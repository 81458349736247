import { useUser } from "api/hooks";
import { ReactComponent as ReferralIcon } from "assets/icons/attach.svg";
import AccountForm from "components/ProfileSetting/AccountForm";
import AML from "components/ProfileSetting/AML";
import AMLModal from "components/ProfileSetting/AMLModal";
import KYC from "components/ProfileSetting/KYC";
import ProfileImage from "components/ProfileSetting/ProfileImage";
import WalletAddress from "components/Shared/WalletAddress";
import Title from "components/UI/Title";
import { useState } from "react";
import "./style.scss";

const ProfileSetting = (): JSX.Element => {
  const [amlModal, setAMLModal] = useState(false);

  const { data: userProfile } = useUser();

  return (
    <div className="profile-page">
      <div className="profile-page__header">
        <div className="profile-page__title profile-page__header-item">
          <Title mainTitle="Profile Setting" badge="[001]" />
          <div className="profile-page__referral">
            <ReferralIcon />
            <div className="profile-page__referral-code">
              <span className="profile-page__referral-label">
                Referral Code:
              </span>
              <span className="profile-page__referral-value">
                {userProfile?.user.referral}
              </span>
            </div>
          </div>
        </div>

        <div className="profile-page__aml profile-page__header-item">
          <AML />
          {/* TODO: remove aml modal */}
          <AMLModal modal={amlModal} setModal={setAMLModal} />
        </div>

        <div className="profile-page__wallet profile-page__header-item">
          <WalletAddress />
        </div>
      </div>

      <div className="profile-page__content">
        <div className="profile-page__left">
          <ProfileImage />
          <KYC />
        </div>

        <div className="profile-page__right">
          <AccountForm />
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
