import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as RejectIcon } from "assets/icons/reject.svg";
import { useMemo } from "react";
import "./style.scss";

type Status = "pending" | "rejected" | "approved";

type Props = {
  status: Status;
};

const STATUS_MAPPER: Record<
  Status,
  { label: string; Icon: React.FunctionComponent }
> = {
  pending: {
    Icon: ClockIcon,
    label: "Pending",
  },
  rejected: {
    Icon: RejectIcon,
    label: "Rejected",
  },
  approved: {
    Icon: CheckIcon,
    label: "Approved",
  },
};

const TableStatus = ({ status }: Props): JSX.Element => {
  const { label } = useMemo(() => STATUS_MAPPER[status], [status]);

  return <div className={`status ${status}`}>{label}</div>;
};

export default TableStatus;
