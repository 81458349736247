import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { ReactComponent as Arrow } from "assets/icons/arrowdown.svg";
import { useMemo, useState } from "react";
import "./style.scss";

type Option = {
  value: string | number;
  label?: string;
  icon?: string | JSX.Element;
};

interface PropTypes {
  label?: string;
  icon?: React.ReactNode;
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
  errorMessage?: string;
}

const Select = ({
  options,
  icon,
  label,
  value,
  onChange,
  errorMessage,
}: PropTypes): JSX.Element => {
  const [active, setActive] = useState(false);

  const hasError = useMemo(() => Boolean(errorMessage), [errorMessage]);

  const selectedValue = useMemo(
    () => options.find(({ value: option }) => option === value),
    [value, options],
  );

  return (
    <div className="custom-select">
      {label && <div className="custom-select__label">{label}</div>}

      <div
        className={`custom-select__wrapper ${active && "custom-select__wrapper--active"}`}
        onClick={() => setActive(!active)}
      >
        <div
          className={`custom-select__header ${hasError && "custom-select__headerWithError"}`}
        >
          {icon && <div className="input__icon">{icon}</div>}
          {selectedValue?.icon && (
            <div className="custom-select__icon">{selectedValue.icon}</div>
          )}
          <div className="custom-select__text">
            {selectedValue?.label || "Select"}
          </div>
          <div className="custom-select__arrow">
            <Arrow />
          </div>
        </div>
        <div className="custom-select__list">
          {options?.map(({ value, icon, label }) => (
            <div
              key={value}
              className="custom-select__item"
              onClick={() => {
                onChange(value);
              }}
            >
              {icon && <div className="custom-select__item-icon">{icon}</div>}
              <div className="custom-select__item-text">{label || value}</div>
            </div>
          ))}
        </div>
      </div>

      {errorMessage && (
        <div className="input__error">
          <AlertIcon />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default Select;
