import "./style.scss";
import Modal from "components/UI/Modal";
import Button from "components/UI/Button";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import Input from "components/UI/Input";
import Select from "components/UI/Select";

interface PropTypes {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AMLModal({ modal, setModal }: PropTypes) {
  return (
    <Modal setModal={setModal} title="Start AML" show={modal}>
      <div className="portfolio-modal">
        <div className="content">
          <div className="upload-logo">
            {" "}
            <label className="label">
              <input type="file" required />
              <UploadIcon />
              Upload Address
            </label>
          </div>
          <div className="flex">
            <Input label="Job Title" placeholder="Enter Your Job Title" />
            <Select
              options={[{ value: "Contractor" }, { value: "Full time" }]}
              value=""
              onChange={() => {
                //
              }}
              label="Employment Status"
            />
          </div>
          <Input
            label="Primary Country of Residence Or Business"
            placeholder="Staking Income"
          />
          <div className="flex">
            <Input
              label="Primary Source Of Funds"
              placeholder="Enter Your Job Title"
            />
            <Input
              label="Secondary Source Of Funds"
              placeholder="Enter Your Job Title"
            />
          </div>
          <div style={{ marginBottom: "80px" }}>
            <Input
              label="Others Source Of Wealth"
              placeholder="Enter Your Others Source Of Wealth"
            />
          </div>
          <div className="button">
            <Button badge="001" title="Request AML" variant="primary" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
