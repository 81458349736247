import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import {
  forwardRef,
  Ref,
  useMemo,
  useState,
  type HTMLInputTypeAttribute,
} from "react";
import "./style.scss";

type Props = {
  icon?: React.ReactNode;
  label?: string;
  placeholder?: string;
  autoComplete?: HTMLInputElement["autocomplete"];
  button?: any;
  type?: HTMLInputTypeAttribute;
  errorMessage?: string;
};

const Input = forwardRef(
  (
    {
      icon,
      label,
      placeholder,
      autoComplete,
      button,
      type = "text",
      errorMessage,
      ...restInputProps
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [isPasswordRevealed, setIsPasswordRevealed] =
      useState<boolean>(false);

    const hasError = useMemo(() => Boolean(errorMessage), [errorMessage]);

    return (
      <div className="input__container">
        <div className="input__label">{label}</div>
        <div className={`input__box ${hasError && "input__boxWithError"}`}>
          <div className="input__icon">{icon}</div>
          <input
            className={`input__input ${type === "password" ? "input__input--password" : ""}`}
            autoComplete={autoComplete}
            type={isPasswordRevealed ? "text" : type}
            placeholder={placeholder}
            ref={ref}
            {...restInputProps}
          />

          {type === "password" && (
            <button
              className="input__button"
              type="button"
              onClick={() =>
                setIsPasswordRevealed(
                  (isPasswordRevealed) => !isPasswordRevealed,
                )
              }
            >
              {isPasswordRevealed ? "hide" : "show"}
            </button>
          )}
        </div>

        {errorMessage && (
          <div className="input__error">
            <AlertIcon />
            <span>{errorMessage}</span>
          </div>
        )}
      </div>
    );
  },
);

export default Input;
