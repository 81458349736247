import { useInvestments, useUser } from "api/hooks";
import { ReactComponent as SubIcon } from "assets/icons/allAssets.svg";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import RequestTable from "components/withdrawals/RequestTable";
import WithdrawalCard from "components/withdrawals/WithdrawalCard";
import WithdrawalModal from "components/withdrawals/WithdrwalModal";
import { useWithdraw } from "models";
import { useCallback, useState } from "react";
import "./style.scss";

const Withdrawal = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { setInvestment, reset } = useWithdraw();
  const { data: userInfo } = useUser();
  const { data: investmentsInfo } = useInvestments({
    pageIndex: 1,
    pageSize: 100, // TODO: should we include pagination?
  });

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset]);

  return (
    <div className="withdrawal">
      <WithdrawalModal isOpen={isOpen} onClose={handleCloseModal} />
      <div className="withdrawal__row">
        <div className="withdrawal__header">
          <Subtitle subtitle="All Assets" icon={<SubIcon />} />
          <Title mainTitle="Pending Requests" badge="[001]" />
        </div>
        <div className="withdrawal__body">
          <RequestTable />
        </div>
      </div>
      <div className="withdrawal__row">
        <div className="withdrawal__header">
          <Subtitle subtitle="All Assets" icon={<SubIcon />} />
          <Title mainTitle="Withdrawals" badge="[002]" />
        </div>
        <div className="withdrawal__content">
          {investmentsInfo?.investments.map(({ withdrawableAmount, id }) => {
            const investment = {
              withdrawableAmount,
              walletAddress: userInfo?.user.walletAddress!, // TODO: should we include wallet address within the investment object?
            };

            return (
              <WithdrawalCard
                key={id}
                investment={investment}
                onClick={() => {
                  setInvestment(investment);
                  setIsOpen(true);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
