import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import "./style.scss";

type Variant =
  | "primary"
  | "primary-inverted"
  | "secondary"
  | "info"
  | "warning";
interface propTypes {
  variant?: Variant;
  type?: "submit" | "reset" | "button";
  title?: string;
  badge?: string;
  arrow?: boolean;
  icon?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  form?: string;
  onClick?: () => void;
}

const variantClassName: Record<Variant, string> = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  warning: "btn-warning",
  info: "btn-info",
  "primary-inverted": "btn-primary-inverted",
};

export default function Button({
  variant = "info",
  type = "button",
  isLoading = false,
  disabled = false,
  title,
  badge,
  arrow,
  icon,
  className,
  onClick,
  form,
  ...rest
}: propTypes) {
  return (
    <button
      className={`button ${variantClassName[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      form={form}
      type={type}
      {...rest}
    >
      {icon && <div className="button__icon">{icon}</div>}
      {/* TODO: do we have some design for loading state of a button? */}
      <div className="button__title">{isLoading ? "Loading..." : title}</div>
      {arrow && <Arrow />}
      <div className="button--badge">{badge}</div>
    </button>
  );
}
