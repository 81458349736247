import Profile from "components/Shared/Profile";
import { TableColumn } from "components/Shared/Table";
import { KYC_STATUS } from "config";
import { formatAmount } from "utils";

const investorsLeaderboardTableColumns: TableColumn[] = [
  {
    header: "#",
    accessorKey: "rank",
    cell: (info) => (
      <div className="leaderboard-table__profile">
        <span className="leaderboard-table__rank">
          {info.getValue() as any}
        </span>
        <Profile
          kycStatus={KYC_STATUS["APPROVED"]}
          name={info.row.original.username as any}
          hideType
        />
      </div>
    ),
  },
  {
    header: "Amount",
    accessorKey: "totalInvested",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
  {
    header: "Profit",
    accessorKey: "totalProfit",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
  {
    header: "APY",
    accessorKey: "activeInvestments",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
];

const referralsLeaderboardTableColumns: TableColumn[] = [
  {
    header: "#",
    accessorKey: "rank",
    cell: (info) => (
      <div className="leaderboard-table__profile">
        <span className="leaderboard-table__rank">
          {info.getValue() as any}
        </span>
        <Profile
          kycStatus={KYC_STATUS["APPROVED"]}
          name={info.row.original.referrerUsername as any}
          hideType
        />
      </div>
    ),
  },
  {
    header: "Code",
    accessorKey: "referralCode",
  },
  {
    header: "Total Referrals",
    accessorKey: "totalReferrals",
  },
  {
    header: "Active Referrals",
    accessorKey: "activeReferrals",
  },
  {
    header: "Total Investment Amount",
    accessorKey: "totalInvestmentAmount",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
];

export { investorsLeaderboardTableColumns, referralsLeaderboardTableColumns };
