import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import Button from "components/UI/Button";

export default function NavItem() {
  return (
    <div className="dnavbar__right">
      <div className="dnavbar__right-icons">
        <button className="dnavbar__right-icon">
          <StarIcon />
        </button>
        <button className="dnavbar__right-icon">
          <ClockIcon />
        </button>
        <button className="dnavbar__right-icon">
          <ListIcon />
        </button>
      </div>
      <div className="dnavbar__button">
        <Button
          variant="primary-inverted"
          title="Connect Wallet"
          badge="003"
          arrow={false}
        />
      </div>
    </div>
  );
}
