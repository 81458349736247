import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { TableColumn } from "components/Shared/Table";
import ActionButton from "components/Shared/TableCells/ActionButton";
import Project from "components/Shared/TableCells/Project";
import { formatAmount } from "utils";

const walletManagementTableConfig: TableColumn[] = [
  {
    header: "Token",
    accessorKey: "token",
    cell: () => (
      <Project detail="Block Chain Investment" project="Tether (USDT)" />
    ),
  },
  {
    header: "Price",
    accessorKey: "price",
    cell: (info) =>
      formatAmount({ amount: info.getValue() as any, currency: "USD" }),
  },
  {
    header: "Balance",
    accessorKey: "balance",
    cell: (info) =>
      formatAmount({ amount: info.getValue() as any, currency: "USD" }),
  },
  {
    header: "",
    accessorKey: "#",
    cell: (info) => (
      <div className="wallet-management-row__actions">
        <ActionButton
          icon={<ArrowIcon />}
          variant="white"
          title="Withdraw"
          path="/withdraw"
        />
        <ActionButton
          icon={<PlusIcon />}
          variant="white"
          title="Buy More"
          path="/buy"
        />
      </div>
    ),
  },
];

export { walletManagementTableConfig };
