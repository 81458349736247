import { useKycStatus } from "api/hooks";
import KYCImage from "assets/images/kyc.png";
import Button from "components/UI/Button";
import { KYC_STATUS } from "config";
import { useMemo } from "react";
import "./style.scss";
import { usePlaidLink } from "react-plaid-link";

const KYC_DETAILS: Record<
  KYC_STATUS,
  {
    label: string;
    action: { label: string };
  }
> = {
  [KYC_STATUS.APPROVED]: {
    label: "KYC Confirmed",
    action: { label: "KYC Information" },
  },
  [KYC_STATUS.REJECTED]: {
    label: "KYC Not Confirmed",
    action: { label: "Start KYC" },
  },
  [KYC_STATUS.PENDING]: {
    label: "KYC Confirming",
    action: { label: "KYC Information" },
  },
  [KYC_STATUS.REVIEWING]: {
    label: "KYC Confirming",
    action: { label: "KYC Information" },
  },
};

const KYC = (): JSX.Element => {
  const { data: kyc } = useKycStatus();

  const { open } = usePlaidLink({
    token: "",
    onSuccess: (public_token, metadata) => {
      // send public_token to server
    },
  });

  const kycStatus = useMemo(
    () => (kyc == null ? KYC_STATUS["PENDING"] : kyc.status),
    [kyc],
  );

  return (
    <div className="kyc">
      <div className="kyc__title">KYC Management</div>
      <div className="kyc__content">
        <img src={KYCImage} className="kyc__image" alt="" />
        <div className={`kyc__status kyc__status--${kycStatus}`}>
          {KYC_DETAILS[kycStatus].label}
        </div>
        <div className="kyc__description">
          KYC ensures compliance, prevents fraud, boosts user trust, and
          protects our app from legal and financial risks
        </div>
        <div className="kyc__button">
          <Button
            variant="secondary"
            onClick={() => open()}
            // disabled={!ready}
            title={KYC_DETAILS[kycStatus].action.label}
          />
        </div>
      </div>
    </div>
  );
};

export default KYC;
