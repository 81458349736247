import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher } from "utils";

type Asset = {
  token: string;
  price: number;
  balance: number;
};

type Assets = {
  assets: Array<Asset>;
};

const useAssets = () => {
  const { token } = useAuthorization();

  return useSWR<Assets>(token ? "/users/custody" : null, fetcher.get);
};

export { useAssets };
