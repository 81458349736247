import { TableColumn } from "components/Shared/Table";
import Links from "components/Shared/TableCells/Links";
import WithScale from "components/Shared/TableCells/WithScale";
import { formatAmount, formatDate } from "utils";

const latestTransactionsTableConfig: TableColumn[] = [
  {
    header: "#",
    accessorKey: "id",
    cell: (info) => (
      <WithScale
        value={info.getValue() as any}
        type={(info.row.original as any).type}
      />
    ),
  },
  {
    header: "Amount",
    accessorKey: "amount",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
  {
    header: "Created At",
    accessorKey: "createdAt",
    cell: (info) => formatDate({ date: info.getValue() as any }),
  },
  {
    header: "",
    accessorKey: "link",
    cell: (info) => (
      <Links link={info.getValue() as any} title="View Transaction" />
    ),
  },
];

export { latestTransactionsTableConfig };
