import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import Image from "assets/images/robot.png";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useRedirect } from "routes/hooks";
import { Path } from "routes/types";
import useSWRMutation from "swr/mutation";
import { fetcher, toast } from "utils";
import * as z from "zod";

interface ForgotPasswordResponse {
  email: string;
}

interface ForgotPasswordRequest {
  email: string;
}

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Required" })
    .email({ message: "Invalid email" }),
});

type SchemaType = z.infer<typeof schema>;

const ForgotPassword = (): JSX.Element => {
  const { goToPath } = useRedirect();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  });

  const { trigger, isMutating } = useSWRMutation(
    "/users/forgot-password",
    fetcher.post<ForgotPasswordRequest, ForgotPasswordResponse>,
  );

  const onSubmit = async ({ email }: SchemaType) => {
    try {
      await trigger({ email });
      goToPath(`/forgot-password/totp?email=${email}` as Path);
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img src={Image} className="main-content__icon-file" alt="" />
          </div>
          <div className="main-content__title">Forget Password</div>
          <div className="main-content__text">
            Trusted by millions. Creso is a secure wallet making the world of{" "}
            <span className="highlighted">web3</span> accessible to all.
          </div>
        </div>
        <form
          id="forgot-password"
          onSubmit={handleSubmit(onSubmit)}
          className="main-content__form"
        >
          <Input
            label="Email"
            icon={<EmailIcon />}
            placeholder="Enter Your email address"
            {...register("email")}
            errorMessage={errors.email?.message}
          />
        </form>
      </div>

      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you need help
            <Link className="highlighted" to="/sign-up">
              {" "}
              Contact Us
            </Link>
          </span>
        </div>
        <div className="main-button">
          <Button
            type="submit"
            form="forgot-password"
            isLoading={isMutating}
            title="Next"
            variant="primary"
            badge="001"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
