import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/telegram.svg";
import "./style.scss";
export default function Footer() {
  return (
    <div className="footer">
      <div className="social-media">
        <button>
          <XIcon />
        </button>
        <button>
          <TelegramIcon />
        </button>
      </div>
      <p>© all rights reserved.</p>
    </div>
  );
}
