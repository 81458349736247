import { ReactComponent as Exclamation } from "assets/icons/exclamation.svg";
import TokenImage from "assets/images/tether.png";
import Button from "components/UI/Button";
import { formatAmount, truncateString } from "utils";
import "./style.scss";

type Props = {
  investment: { walletAddress: string; withdrawableAmount: number };
  onClick: () => void;
};

const WithdrawalCard = ({ investment, onClick }: Props): JSX.Element => {
  return (
    <div className="withdraw-card">
      <div className="withdraw-card__info">
        <Exclamation />
      </div>
      <div className="withdraw-card__header">
        <div className="withdraw-card__image">
          <img src={`${TokenImage}`} alt="Token" />
        </div>
        <div className="withdraw-card__value">
          {formatAmount({
            amount: investment.withdrawableAmount,
            currency: "USD",
          })}
        </div>
        <div className="withdraw-card__address">
          {truncateString({
            str: investment.walletAddress,
            options: { truncation: "middle" },
          })}
        </div>
      </div>
      <div className="withdraw-card__body">
        <div className="withdraw-card__amount">
          <span className="withdraw-card__label">Withdrawable:</span>
          <span className="withdraw-card__amount-value">
            {formatAmount({
              amount: investment.withdrawableAmount,
              currency: "USD",
            })}
          </span>
        </div>
        <Button
          title="Request withdraw"
          variant="secondary"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default WithdrawalCard;
