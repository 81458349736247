import { useAssets } from "api/hooks";
import Table from "components/Shared/Table/Table";
import "./style.scss";
import { walletManagementTableConfig } from "./tableConfig";

const WalletManagementTable = (): JSX.Element => {
  const { data: assetsInfo, isLoading } = useAssets();

  return (
    <Table
      data={assetsInfo?.assets}
      columns={walletManagementTableConfig}
      isLoading={isLoading}
      withPagination={false}
    />
  );
};

export default WalletManagementTable;
