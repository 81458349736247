import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { ReactComponent as Arrow } from "assets/icons/arrowdown.svg";
import currency from "currency.js";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import "./style.scss";

const ALLOWED_PERCENTAGES = [
  { label: "25%", value: 0.25 },
  { label: "50%", value: 0.5 },
  { label: "75%", value: 0.75 },
  { label: "100%", value: 1 },
];

const getSchema = ({ balance }: { balance: number }) =>
  z
    .object({
      amount: z.coerce.number({
        required_error: "Required",
        invalid_type_error: "Required",
      }),
    })
    .refine((data) => currency(data.amount).value <= currency(balance).value, {
      message: "Selected amount cannot exceed withdrawable amount",
      path: ["amount"],
    });

type SchemaType = z.infer<ReturnType<typeof getSchema>>;

type Option = {
  label: string;
  value: string;
  icon?: string;
};

type Props = {
  options: Array<Option>;
  value: Option["value"];
  onChange?: (value: Option["value"]) => void;
  investment?: { walletAddress: string; withdrawableAmount: number };
  setAmount: (amount: number) => void;
};

const SelectBox = ({
  options,
  value,
  setAmount,
  investment,
  onChange,
}: Props): JSX.Element => {
  const {
    register,
    setValue,
    watch,
    trigger: revalidate,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(
      getSchema({ balance: investment?.withdrawableAmount ?? 0 }),
    ),
    mode: "all",
  });

  const errorMessage = useMemo(
    () => errors.amount?.message,
    [errors.amount?.message],
  );

  useEffect(() => {
    setAmount(watch("amount"));
    revalidate("amount");
  }, [watch, setAmount, revalidate]);

  const onSelectMaxAmount = useCallback(() => {
    setValue("amount", investment?.withdrawableAmount ?? 0);
  }, [setValue, investment?.withdrawableAmount]);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value)!,
    [options, value],
  );

  const allowedToSelectOption = useMemo(
    () => options.length > 1,
    [options.length],
  );

  const activePercentage = ALLOWED_PERCENTAGES.find(
    ({ value }) =>
      currency(watch("amount")).divide(investment?.withdrawableAmount ?? 0)
        .value === value,
  )?.value;

  return (
    <>
      <div>
        <div className="select-box">
          {allowedToSelectOption ? (
            <div className="select">
              <div className="select-btn">
                <>
                  {selectedOption ? (
                    <>
                      {" "}
                      {selectedOption.icon ? (
                        <div className="select-value">
                          <img src={selectedOption.icon} alt="" />
                          {selectedOption.label}
                        </div>
                      ) : (
                        <div>{selectedOption.label}</div>
                      )}
                    </>
                  ) : (
                    <>Select</>
                  )}
                  <div className="arrow">
                    <Arrow />
                  </div>
                </>
              </div>
              <div className="select-content">
                {options.map(({ label, value, icon }) => (
                  <div
                    className="select-item"
                    onClick={() => onChange?.(value)}
                    key={value}
                  >
                    {icon ? (
                      <div className="select-value">
                        <img src={icon} alt={label} />
                        {label}
                      </div>
                    ) : (
                      <div>{label}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="select">
              <div className="select-btn">
                <div className="select-value">
                  <img src={selectedOption.icon} alt="" />
                  {selectedOption.label}
                </div>
              </div>
            </div>
          )}

          <input
            placeholder="Enter Amount"
            inputMode="decimal"
            type="number"
            {...register("amount", {
              valueAsNumber: true,
            })}
          />
          <button type="button" onClick={onSelectMaxAmount}>
            MAX
          </button>
        </div>

        {errorMessage && (
          <div
            className="token-input__balance error-message"
            style={{ marginTop: "12px" }}
          >
            <AlertIcon />
            <span>{errorMessage}</span>
          </div>
        )}
      </div>

      <div className={`percents ${!investment && "disabled"}`}>
        {ALLOWED_PERCENTAGES.map(({ label, value }) => (
          <div
            key={value}
            className={`percents__item ${activePercentage === value ? "selected-percent" : ""}`}
            onClick={() => {
              setValue(
                "amount",
                currency(investment?.withdrawableAmount ?? 0).multiply(value)
                  .value,
              );
            }}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};

export default SelectBox;
