import { MoonPayBuyWidget, MoonPayProvider } from "@moonpay/moonpay-react";
import { ReactNode } from "react";
import { env } from "./envalid";
import { useUser } from "api/hooks";

const PurchaseCryptoProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <MoonPayProvider apiKey={env.REACT_APP_MOON_PAY_API_KEY}>
      {children}
    </MoonPayProvider>
  );
};

const PurchaseCryptoWidget = (): JSX.Element => {
  const { data: userProfile } = useUser();

  return (
    <MoonPayBuyWidget
      variant="embedded"
      theme="dark"
      baseCurrencyCode="usd"
      baseCurrencyAmount="100"
      currencyCode="usdt"
      defaultCurrencyCode="usdt"
      externalCustomerId={userProfile?.user.id}
      // TODO: confirm if we need these props
      email={userProfile?.user.email}
      onLogin={async (info) => console.log("MoonPay ~> ", { info })}
      // TODO: to be configured
      externalTransactionId=""
      redirectURL=""
      colorCode="#68f029"
      themeId=""
      // TODO: load the widget only if user is authenticated
      visible
    />
  );
};

export { PurchaseCryptoProvider, PurchaseCryptoWidget };
