import { useReferralsLeaderboard } from "api/hooks";
import { useTablePagination } from "components/Shared/Table";
import Table from "components/Shared/Table/Table";
import { referralsLeaderboardTableColumns } from "./tableConfig";

const ReferralsLeaderboardTable = (): JSX.Element => {
  const [pagination, setPagination] = useTablePagination();

  const { data, isLoading } = useReferralsLeaderboard(pagination);

  return (
    <Table
      columns={referralsLeaderboardTableColumns}
      data={data?.leaderboard}
      isLoading={isLoading}
      onPaginationChange={setPagination}
    />
  );
};

export default ReferralsLeaderboardTable;
