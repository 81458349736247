import { create } from "zustand";

type Investment = { walletAddress: string; withdrawableAmount: number };

type State = {
  investment: Investment | undefined;
  amount: number | undefined;
  setInvestment: (investment: Investment) => void;
  setAmount: ({ amount }: { amount: number }) => void;
  reset: () => void;
};

const useWithdraw = create<State>()((set) => ({
  investment: undefined,
  amount: undefined,
  setInvestment: (investment) => set(() => ({ investment })),
  setAmount: ({ amount }) => set(() => ({ amount })),
  reset: () => set(() => ({ investment: undefined, amount: undefined })),
}));

export { useWithdraw };
