import { useWithdrawals } from "api/hooks";
import { useTablePagination } from "components/Shared/Table";
import Table from "components/Shared/Table/Table";
import { latestTransactionsTableConfig } from "./tableConfig";

const RequestTable = (): JSX.Element => {
  const [pagination, setPagination] = useTablePagination();
  const {
    data: withdrawalsInfo,
    isLoading,
    isValidating,
  } = useWithdrawals(pagination);

  if (isLoading) return <></>;

  return (
    <Table
      data={withdrawalsInfo?.withdrawals}
      columns={latestTransactionsTableConfig}
      rowCount={withdrawalsInfo?.pagination.total}
      isLoading={isValidating}
      onPaginationChange={setPagination}
    />
  );
};

export default RequestTable;
