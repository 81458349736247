import "./style.scss";
import Logo from "components/Shared/Logo";
import Button from "components/UI/Button";

export default function MainLayout() {
  return (
    <div className="main-header">
      <div className="main-header__logo-section">
        <Logo />
      </div>

      <div className="main-header__nav" />
      <div className="main-header__wallet-button">
        <Button variant="primary-inverted" title="Connect Wallet" />
      </div>
    </div>
  );
}
