import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher } from "utils";

enum TYPE {
  "SEND" = "send",
  "RECEIVE" = "receive",
}

type Transaction = {
  id: string;
  link: string;
  amount: number;
  createdAt: string;
  type: TYPE;
};

type Response = {
  transactions: Array<Transaction>;
};

const useTransactions = () => {
  const { token } = useAuthorization();

  // TODO: update the endpoint
  return useSWR<Response>(token ? "/users/transactions" : null, fetcher.get);
};

export { useTransactions };
