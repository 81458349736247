import { TableColumn } from "components/Shared/Table";
import Project from "components/Shared/TableCells/Project";
import WithArrow from "components/Shared/TableCells/WithArrow";
import { formatDate } from "utils";

const performanceTableConfig: TableColumn[] = [
  {
    header: "Project",
    accessorKey: "project",
    cell: () => (
      <Project project="Block Chain Investment" detail="Tether (USDT)" />
    ),
  },
  {
    header: "Category",
    accessorKey: "category",
    cell: () => "Crypto Currency",
  },
  {
    header: "Profit",
    accessorKey: "profitEarned",
    cell: (info) => <WithArrow type="increase" value={`${info.getValue()}%`} />,
  },
  {
    header: "Date",
    accessorKey: "createdAt",
    cell: (info) => formatDate({ date: info.getValue() as any }),
  },
];

export { performanceTableConfig };
