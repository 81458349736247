import { ReactComponent as Check } from "assets/icons/check.svg";
import "./style.scss";
import { type InvestmentPlan } from "api/hooks";

interface PropTypes {
  selected?: boolean;
  onSelect?: () => void;
  onMore?: () => void;
  plan?: InvestmentPlan;
}

export default function PlanCard({
  selected,
  plan,
  onSelect,
  onMore,
}: PropTypes) {
  return (
    <div
      className={`investment-card ${selected ? "active" : ""}`}
      onClick={onSelect}
    >
      <div className="investment-card__head">
        <div className="investment-card__selected">
          <Check />
        </div>
        <div className="investment-card__id">
          {plan?.name} <sup>Y</sup>
        </div>
        <div className="investment-card__apy">{plan?.profitRate}% APY</div>
      </div>
      <div className="investment-card__body">
        <div className="investment-card__row">
          <div className="investment-card__label">Minimum Investment:</div>
          <div className="investment-card__value">${plan?.minAmount}</div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">Payout Distribution:</div>
          <div className="investment-card__value">
            {plan?.duration}
            <span>Months</span>
          </div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">APY:</div>
          <div className="investment-card__value">
            {plan?.profitRate}
            <span>%</span>
          </div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">Lock Time:</div>
          <div className="investment-card__value">
            {plan?.description}
            <span>Years</span>
          </div>
        </div>
      </div>
      <div className="investment-card__action">
        <button onClick={onMore}>See More</button>
      </div>
    </div>
  );
}
