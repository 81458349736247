import "./style.scss";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as InfoIcon } from "assets/icons/exclamation.svg";
import Modal from "components/UI/Modal";

interface PropTypes {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function StakeModal({ modal, setModal }: PropTypes) {
  return (
    <Modal title="Bronze Plan" show={modal} setModal={setModal}>
      <div className="modal-content-stake">
        <div className="content">
          {" "}
          <div>
            Invest in the Bronze Pool today and take the first step towards a
            secure and profitable future!
          </div>
          <ul className="list">
            <li>
              <div className="icon">
                {" "}
                <CheckIcon />
              </div>
              Payout Distribution: Every <b>4 months</b>
            </li>
            <li>
              <div className="icon">
                {" "}
                <CheckIcon />
              </div>
              Payout Distribution: Every <b>4 months</b>
            </li>
            <li>
              <div className="icon">
                {" "}
                <CheckIcon />
              </div>
              Payout Distribution: Every <b>4 months</b>
            </li>
            <li>
              <div className="icon">
                {" "}
                <CheckIcon />
              </div>
              Payout Distribution: Every <b>4 months</b>
            </li>
          </ul>
          <div>
            Short Lock-In Period: With a minimum lock-in period of just one
            year, you have the flexibility to reassess and reallocate your
            investments sooner.
          </div>
        </div>

        <div className="info">
          <InfoIcon />
          Accessibility: Start investing with a manageable minimum amount.
          Regular Income: Benefit from scheduled quarterly distributions. High
          Yield: Achieve a competitive APY with the security of a short lock-in
          period.
        </div>
      </div>
    </Modal>
  );
}
