import { type PaginationState } from "@tanstack/react-table";
import { useState } from "react";

const useTablePagination = () =>
  useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

export { useTablePagination, type PaginationState };
