import { useUser } from "api/hooks";
import { api, KYC_STATUS } from "config";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import useSWRMutation from "swr/mutation";
import "./style.scss";

const uploader = (url: string, { arg }: { arg: any }) =>
  api
    .put(url, arg, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${(arg as any)._boundary}`,
      },
    })
    .then((res) => res.data);

const ProfileImage = (): JSX.Element => {
  const { data: userProfile } = useUser();

  const { trigger, isMutating } = useSWRMutation(
    "/users/profile/image",
    uploader,
  );

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      const [file] = acceptedFiles;
      const data = new FormData();
      data.append("file", file, file.name);

      await trigger(data);
    },
    [trigger],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 1 * 1000 * 1024,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg", ".webp"],
    },
  });

  const kycStatus = useMemo<KYC_STATUS>(
    () => (userProfile ? userProfile.user.kycStatus : KYC_STATUS["PENDING"]),
    [userProfile],
  );

  return (
    <div className="avatar">
      <h5 className="avatar__title">Upload Image</h5>
      <div className="avatar__content">
        <div className={`avatar__image avatar__image--${kycStatus}`}>
          <img
            src={userProfile?.user.profileImage}
            className="avatar__image-preview"
            alt=""
          />
        </div>

        <div {...getRootProps({ className: "avatar__upload" })}>
          <input {...getInputProps()} />
          <div className="avatar__label">
            <p>{isMutating ? "Uploading..." : "Upload"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;
