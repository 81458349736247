import { useUser } from "api/hooks";
import Profile from "components/Shared/Profile";
import NavItem from "./NavItem";
import "./style.scss";

type Props = {
  onToggle: () => void;
  isOpen: boolean;
};

const Navbar = ({ onToggle }: Props): JSX.Element => {
  const { data: userProfile } = useUser();

  return (
    <div className="dnavbar">
      <div className="dnavbar__profile">
        <div className="dnavbar__toggle" onClick={onToggle}>
          <span />
          <span />
          <span />
        </div>
        <Profile
          kycStatus={userProfile?.user.kycStatus}
          name={userProfile?.user.fullName}
          avatar={userProfile?.user.profileImage}
        />
      </div>

      <NavItem />
    </div>
  );
};

export default Navbar;
