import { useKycMetadata } from "api/hooks";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useRedirect } from "routes/hooks";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./style.scss";

export default function DashboardLayout() {
  const { isKYCApproved } = useKycMetadata();
  const { goToPath } = useRedirect();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isKYCApproved) goToPath("/profile");
  }, [isKYCApproved, goToPath]);

  const toggleOpen = () => {
    setIsOpen((io) => !io);
  };

  return (
    <div className="dashboard">
      <div className={`dashboard__sidebar ${isOpen ? "open" : ""}`}>
        <Sidebar onToggle={toggleOpen} isOpen={isOpen} />
      </div>
      <div className="dashboard__container">
        <Header onToggle={toggleOpen} isOpen={isOpen} />
        <div className="dashboard__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
