import { useTransactions } from "api/hooks";
import Table from "components/Shared/Table/Table";
import { latestTransactionsTableConfig } from "./tableConfig";

const TransactionTable = (): JSX.Element => {
  const { data: latestTransactions, isLoading } = useTransactions();

  return (
    <Table
      data={latestTransactions?.transactions}
      columns={latestTransactionsTableConfig}
      withPagination={false}
      isLoading={isLoading}
    />
  );
};

export default TransactionTable;
