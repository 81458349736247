import { INVESTMENT_PLAN_STATUS, useInvestmentPlans } from "api/hooks";
import { ReactComponent as AssetIcon } from "assets/icons/allAssets.svg";
import WalletAddress from "components/Shared/WalletAddress";
import LockedSection from "components/Stake/LockedSection";
import PlanCard from "components/Stake/PlanCard";
import StakeModal from "components/Stake/StakeModal";
import Button from "components/UI/Button";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import { useStake } from "models";
import { useCallback, useState } from "react";
import useSWRMutation from "swr/mutation";
import { fetcher, toast } from "utils";
import "./style.scss";

interface InvestmentResponse {
  success: boolean;
  message: string;
}

interface InvestmentRequest {
  planId: string;
  amount: number;
}

const StakingPlans = (): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);

  const { setId, id, amount, reset } = useStake();
  const { data: investment } = useInvestmentPlans();

  const { trigger, isMutating } = useSWRMutation(
    "/investments",
    fetcher.post<InvestmentRequest, InvestmentResponse>,
  );

  const onSubmit = useCallback(async () => {
    if (amount && id)
      try {
        const response = await trigger({ amount, planId: id });
        toast.success(response.message);
        reset();
      } catch (error) {
        toast.error("Something went wrong.");
      }
  }, [amount, id, reset, trigger]);

  return (
    <div className="stake">
      <StakeModal modal={modal} setModal={setModal} />
      <div className="stake__header">
        <div className="stake__header-left">
          <Subtitle subtitle="All Assets" icon={<AssetIcon />} />
          <Title mainTitle="Staking" badge="[001]" />
        </div>
        <WalletAddress />
      </div>
      <div className="stake__plans">
        <h3 className="stake__head">Select Plan</h3>
        <div className="stake__plans-wrapper">
          {investment?.plans
            ?.filter(({ status }) => status === INVESTMENT_PLAN_STATUS.ACTIVE)
            ?.map((plan) => (
              <PlanCard
                onSelect={() => setId({ id: plan.id })}
                key={plan.id}
                onMore={() => setModal(true)}
                selected={id === plan.id}
                plan={plan}
              />
            ))}
        </div>
      </div>

      {id && (
        <div className="stake__form">
          <LockedSection />
        </div>
      )}

      {id && amount && (
        <div className="stake__actions">
          <div />
          <Button
            title={"Stake Now"}
            variant="primary"
            badge="003"
            isLoading={isMutating}
            onClick={onSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default StakingPlans;
