import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { type Path } from "routes/types";

const useRedirect = () => {
  const navigate = useNavigate();

  const goToPath = useCallback(
    (path: Path) => {
      navigate(path);
    },
    [navigate],
  );

  return { goToPath };
};

export { useRedirect };
