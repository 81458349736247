import {
  AVAILABLE_BALANCE_FILTER_BY,
  useAvailableBalance,
  useAvailableBalanceFilterState,
} from "api/hooks";
import { ReactComponent as AssetIcon } from "assets/icons/allAssets.svg";
import AvailableBalance from "components/Dashboard/AvailableBalance";
import TransactionTable from "components/Dashboard/TransactionTable";
import WithdrawalTable from "components/Dashboard/WithdrawTable";
import Dropdown from "components/UI/Dropdown";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import "./style.scss";

const Dashboard = (): JSX.Element => {
  const [filterBy, setFilterBy] = useAvailableBalanceFilterState();
  const { data } = useAvailableBalance({ filterBy });

  return (
    <div className="home">
      <div className="home__cell">
        <div className="home__header">
          <div className="home__header-left">
            <Subtitle subtitle="All Assets" icon={<AssetIcon />} />
            <Title mainTitle="My Deposit" badge="[001]" />
          </div>
          <Dropdown
            options={AVAILABLE_BALANCE_FILTER_BY}
            value={filterBy}
            placeholder="Period"
            onChange={(value) => setFilterBy(value)}
          />
        </div>
        <div className="home__body">
          <div className="chart__holder">
            <AvailableBalance dataPoints={(data || []) as Array<{ x: number; y: number }>} />
          </div>
        </div>
      </div>
      <div className="home__row">
        <div className="home__cell">
          <div className="home__header">
            <div className="home__header-left">
              <Title mainTitle="Last Transactions" badge="[003]" />
            </div>
          </div>
          <TransactionTable />
        </div>
        <div className="home__cell">
          <div className="home__header">
            <div className="home__header-left">
              <Title mainTitle="Withdraws" badge="[004]" />
            </div>
          </div>
          <WithdrawalTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
