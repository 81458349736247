import { ReactComponent as Arrow } from "assets/icons/arrowdown.svg";
import { useMemo } from "react";
import "./style.scss";

type Option = {
  label: string;
  value: string | number;
};
type Value = Option["value"];

type Props = {
  options: Array<Option>;
  value: Value | null;
  onChange: (value: any) => void;
  placeholder?: string;
};

const Dropdown = ({
  options,
  value,
  onChange,
  placeholder,
}: Props): JSX.Element => {
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  return (
    <div className="dropdown">
      <button className="dropdown__header">
        <span className="dropdown__header-text">
          {value ? selectedOption?.label : placeholder}
        </span>
        <div className="dropdown__arrow">
          <Arrow />
        </div>
      </button>
      <div className="dropdown__content">
        {options?.map((option) => (
          <button
            key={option.value}
            onClick={() => onChange(option.value)}
            className="dropdown__item"
          >
            <span className="dropdown__item-text">{option.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
