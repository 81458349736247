import { useUser } from "api/hooks";
import "./style.scss";

const WalletAddress = (): JSX.Element => {
  const { data: userProfile } = useUser();

  return (
    <div className="wallet-address">
      <div className="wallet-address__header">
        <div className="wallet-address__header--title">Withdrawal Address:</div>
      </div>
      <div className="wallet-address__input">
        <input
          name="wallet"
          type="text"
          value={userProfile?.user.walletAddress}
          disabled
        />
      </div>
    </div>
  );
};

export default WalletAddress;
