import { create } from "zustand";

type State = {
  id: string | undefined;
  amount: number | undefined;
  setId: ({ id }: { id: string }) => void;
  setAmount: ({ amount }: { amount: number }) => void;
  reset: () => void;
};

const useStake = create<State>()((set) => ({
  id: undefined,
  amount: undefined,
  setId: ({ id }) => set(() => ({ id })),
  setAmount: ({ amount }) => set(() => ({ amount })),
  reset: () => set(() => ({ id: undefined, amount: undefined })),
}));

export { useStake };
