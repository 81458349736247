import { TableColumn } from "components/Shared/Table";
import WithScale from "components/Shared/TableCells/WithScale";
import Button from "components/UI/Button";
import { formatAmount } from "utils";

const latestWithdrawsTableConfig: TableColumn[] = [
  {
    header: "#",
    accessorKey: "amount",
    cell: (info) => (
      <WithScale
        value={formatAmount({
          currency: "USD",
          amount: info.getValue() as any,
        })}
        type="send"
      />
    ),
  },
  {
    header: "",
    accessorKey: "walletAddress",
    cell: (info) => (
      <Button
        variant="secondary"
        title="View Transaction"
        arrow={true}
        onClick={() => {
          window.open(`https://etherscan.io/address/${info.getValue() as any}`);
        }}
      />
    ),
  },
];

export { latestWithdrawsTableConfig };
