import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher, Pagination, preparePagination } from "utils";

enum PERIOD {
  "ALL_TIME" = "all_time",
  "MONTHLY" = "monthly",
  "WEEKLY" = "weekly",
}

type ReferralsLeaderboardEntry = {
  rank: number;
  referralCode: string;
  referrerUsername: string;
  totalReferrals: number;
  activeReferrals: number;
  totalInvestmentAmount: number;
};

type Response = {
  period: PERIOD;
  leaderboard: Array<ReferralsLeaderboardEntry>;
  userRank: ReferralsLeaderboardEntry;
};

const useReferralsLeaderboard = ({ pageIndex, pageSize }: Pagination) => {
  const { token } = useAuthorization();
  const key = "/leaderboard/referrals";

  return useSWR<Response>(token ? key : null, () =>
    fetcher.get(key, {
      ...preparePagination({ pageIndex, pageSize }),
      period: PERIOD.ALL_TIME,
    }),
  );
};

export { useReferralsLeaderboard };
