import { ReactComponent as AssetIcon } from "assets/icons/allAssets.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "components/UI/Button";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import WalletManagementTable from "components/WalletManagment/WalletManagementTable";
import "./style.scss";
import { useRedirect } from "routes/hooks";

const WalletManagement = (): JSX.Element => {
  const { goToPath } = useRedirect();

  return (
    <div className="wallet">
      <div className="wallet__header">
        <div className="wallet__header-left">
          <Subtitle subtitle="All Assets" icon={<AssetIcon />} />
          <Title mainTitle="Wallet Management" badge="[003]" />
        </div>
        <div className="wallet__header-right">
          <div>
            <Button
              variant="secondary"
              title="Withdraw"
              icon={<PlusIcon />}
              onClick={() => {
                goToPath("/withdraw");
              }}
            />
          </div>
          <div>
            <Button
              variant="primary"
              title="Buy Crypto"
              icon={<PlusIcon />}
              onClick={() => {
                goToPath("/buy");
              }}
            />
          </div>
        </div>
      </div>
      <div className="wallet__body">
        <WalletManagementTable />
      </div>
    </div>
  );
};

export default WalletManagement;
