import { useWithdrawals } from "api/hooks";
import Table from "components/Shared/Table/Table";
import { latestWithdrawsTableConfig } from "./tableConfig";

const WithdrawalTable = (): JSX.Element => {
  const { data: latestWithdrawals, isLoading } = useWithdrawals({
    pageIndex: 1,
    pageSize: 5,
  });

  return (
    <Table
      data={latestWithdrawals?.withdrawals}
      columns={latestWithdrawsTableConfig}
      rowCount={latestWithdrawals?.pagination.total}
      isLoading={isLoading}
      withPagination={false}
    />
  );
};

export default WithdrawalTable;
