import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { cookiesStorage } from "./utils";

interface AuthenticationState {
  token: string | undefined;
  authorize: ({ token }: { token: string }) => void;
  deAuthorize: () => void;
}

const useAuthorization = create<AuthenticationState>()(
  persist(
    (set) => ({
      token: undefined,
      authorize: ({ token }) => set(() => ({ token })),
      deAuthorize: () => set(() => ({ token: undefined })),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => cookiesStorage),
    },
  ),
);

export { useAuthorization };
