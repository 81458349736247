import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { useMemo } from "react";
import {
  ScaleXYPropType,
  VictoryArea,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from "victory";
import "./styles.scss";

type LabelPointProps = {
  datum?: { x?: number; y?: number };
  scale?: ScaleXYPropType;
};

const LabelPoint = (props: LabelPointProps) => {
  const x = props.scale?.x(props.datum!.x!);
  const y = props.scale?.y(props.datum!.y!);

  return (
    <>
      <g style={{ opacity: 1, position: "relative" }}>
        <linearGradient
          id="horizontal"
          x1="2"
          y1="1"
          x2="471"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="white" stopOpacity="0" />
          <stop offset="0.4" stopColor="white" stopOpacity="0" />
          <stop offset="0.8" stopColor="white" stopOpacity="1" />
          <stop offset="0.9" stopColor="white" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id="vertical"
          x1="-0.5"
          y1="2.18085e-08"
          x2="-0.5"
          y2="277"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="white" stopOpacity="0" />
          <stop offset="0.5" stopColor="white" stopOpacity="1" />
          <stop offset="0.9" stopColor="white" stopOpacity="0" />
        </linearGradient>

        <line
          opacity="0.2"
          x1="-4.37114e-08"
          y1={y}
          x2="471"
          y2={y}
          stroke="url(#horizontal)"
          strokeDasharray="5 5"
        />
        <line
          opacity="0.2"
          x1={x}
          y1="-158"
          x2={x}
          y2="316"
          stroke="url(#vertical)"
          strokeDasharray="5 5"
        />
        <circle
          fill="white"
          cx={x}
          cy={y}
          r={4}
          stroke="#68f029"
          strokeWidth="4px"
        />
      </g>
    </>
  );
};

type Props = {
  dataPoints: Array<{ x: number; y: number }>;
};

const AvailableBalance = ({ dataPoints: data }: Props) => {
  const { maxDomain, minDomain } = useMemo(() => {
    const min = data.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));
    const max = data.reduce((prev, curr) => (prev.y > curr.y ? prev : curr));
    const minDomain = {
      y: min.y / max.y < 0.1 ? 0 : min.y - (max.y - min.y) * 1.05,
    };
    const maxDomain = {
      y:
        min.y / max.y < 0.1
          ? max.y * 1.15
          : max.y + (max.y - minDomain.y) * 0.2,
    };

    return { minDomain, maxDomain };
  }, [data]);

  return (
    <div className="chart">
      <div className="info">
        <div className="availableBalance">
          <div className="availableBalance__subtitle">Available Balance</div>
          <div className="title">
            <div className="title__badge">$</div>
            <div className="title__text">253,100.87</div>
          </div>
        </div>
        <div className="investedBalance">
          <div className="title">
            <Arrow />
            <div className="title__text">$ 3,675.01</div>
            <div className="title__badge">(13.02%)</div>
          </div>
          <div className="investedBalance__subtitle">Invested Balance</div>
        </div>
      </div>

      <div className="chart">
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="chartGradient" x1="0" x2="1" y1="0" y2="0">
              <stop offset="0%" stopColor="rgba(104, 240, 41, 0.1)" />
              <stop offset="60%" stopColor="rgba(104, 240, 41, 0.025)" />
              <stop offset="100%" stopColor="rgba(104, 240, 41, 0)" />
            </linearGradient>
          </defs>
        </svg>

        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="lineGradient" x1="0" x2="1" y1="0" y2="0">
              <stop offset="0%" stopColor="#68F029" />
              <stop offset="90%" stopColor="#68F029" />
              <stop offset="100%" stopColor="#FFFFFF" />
            </linearGradient>
          </defs>
        </svg>

        <VictoryGroup
          minDomain={minDomain}
          maxDomain={maxDomain}
          height={120}
          padding={0}
        >
          <VictoryArea
            data={data}
            style={{ data: { fill: "url(#chartGradient)", strokeWidth: 0 } }}
            interpolation="linear"
          />
          <VictoryLine
            data={data}
            style={{
              data: {
                stroke: "url(#lineGradient)",
                strokeWidth: "2",
              },
            }}
            interpolation="linear"
          />
          <VictoryLine
            data={[
              ...data.map(({ x, y }) => ({
                x,
                y: y * Math.random() + 12.14,
              })),
              ...Array(3)
                .fill("")
                .map((_, index) => ({
                  x: data.length + index + 1,
                  y: Math.random() + Math.PI,
                })),
            ]}
            style={{
              data: {
                stroke: "#FFFFFF1A",
                strokeWidth: "1",
              },
            }}
            interpolation="linear"
          />
          <VictoryScatter
            data={data.slice(-1)}
            dataComponent={<LabelPoint />}
          />
        </VictoryGroup>
      </div>
    </div>
  );
};

export default AvailableBalance;
