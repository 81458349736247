import { zodResolver } from "@hookform/resolvers/zod";
import { useDebounce } from "@uidotdev/usehooks";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import Tether from "assets/images/tether.png";
import Title from "components/UI/Title";
import { USER_BALANCE } from "config";
import currency from "currency.js";
import { useStake } from "models";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { fetcher, formatAmount, formatDate, toast } from "utils";
import * as z from "zod";
import "./style.scss";

interface InvestmentCalculatorResponse {
  investmentAmount: number;
  dailyProfit: number;
  totalProfit: number;
  fees: number;
  duration: number;
  endDate: string;
}

interface InvestmentCalculatorRequest {
  planId: string;
  amount: number;
}

const getSchema = ({ balance }: { balance: number }) =>
  z
    .object({
      amount: z.coerce.number({
        required_error: "Required",
        invalid_type_error: "Required",
      }),
    })
    .refine((data) => currency(data.amount).value <= currency(balance).value, {
      message: "Amount cannot exceed available balance",
      path: ["amount"],
    });

type SchemaType = z.infer<ReturnType<typeof getSchema>>;

const LockedSection = (): JSX.Element => {
  const { setAmount, id, amount } = useStake();

  const {
    trigger,
    isMutating,
    data: calculatedResult,
  } = useSWRMutation(
    "/investments/calculate",
    fetcher.post<InvestmentCalculatorRequest, InvestmentCalculatorResponse>,
  );

  const debouncedTrigger = useDebounce(trigger, 1000);

  const balance = USER_BALANCE;

  const {
    register,
    setValue,
    watch,
    trigger: revalidate,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(getSchema({ balance })),
    mode: "all",
  });

  const errorMessage = useMemo(
    () => errors.amount?.message,
    [errors.amount?.message],
  );

  useEffect(() => {
    setAmount({ amount: watch("amount") });
    (async () => {
      if (debouncedTrigger && amount && id)
        try {
          await debouncedTrigger({ amount, planId: id });
        } catch (error) {
          toast.error("Something went wrong.");
        }
    })();
  }, [amount, debouncedTrigger, id, setAmount, watch]);

  const onSelectMaxAmount = useCallback(() => {
    setValue("amount", balance);
    revalidate("amount");
  }, [balance, setValue, revalidate]);

  return (
    <div className="lock">
      <div className="lock__calculator">
        <div className="lock__entry">
          <Title mainTitle="Locked Amount" badge="[003]" />

          <div className="token-input">
            <div
              className={`token-input__body ${errorMessage && "token-input__bodyWithError"}`}
            >
              <div className="token-input__info">
                <img className="token-input__img" src={Tether} alt="tether" />
                <div className="token-input__name">Tether</div>
                {/* <div className="token-input__arrow">
                  <Arrow />
                </div> */}
              </div>
              <input
                className="token-input__input"
                placeholder="Enter Amount"
                inputMode="decimal"
                disabled={isMutating}
                type="number"
                {...register("amount", {
                  valueAsNumber: true,
                })}
              />
              <button className="token-input__max" onClick={onSelectMaxAmount}>
                MAX
              </button>
            </div>

            {errorMessage && (
              <div className="token-input__balance error-message">
                <AlertIcon />
                <span>{errorMessage}</span>
              </div>
            )}
            <div className="token-input__balance">
              <span className="token-input__label">Your Balance:</span>
              <span className="token-input__value">
                {formatAmount({ amount: balance, currency: "USDT" })}
              </span>
            </div>
          </div>
        </div>

        <div className="lock__calculation">
          <div className="lock__calculation-row">
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Share:</div>
                <div className="lock__calculation-value">
                  0.0007<span className="lock__calculation-unit">%</span>
                </div>
              </div>
            </div>
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Tokens per Year:</div>
                <div className="lock__calculation-value">
                  506<span className="lock__calculation-unit">USDT</span>
                </div>
              </div>
            </div>
          </div>
          <div className="lock__calculation-row lock__calculation-row--highlighted">
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Earning:</div>
                <div className="lock__calculation-value">
                  {formatAmount({
                    amount: calculatedResult?.totalProfit ?? 0,
                    currency: "USD",
                  })}
                  <span className="lock__calculation-unit">
                    {formatAmount({
                      amount: calculatedResult?.investmentAmount ?? 0,
                      currency: "USDT",
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lock__details">
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Stake Date:</div>
            <div className="lock__details-value">June 6 , 2022 06:45 AM</div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Value Date:</div>
            <div className="lock__details-value">June 7 , 2022 05:00 AM</div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">interest end time:</div>
            <div className="lock__details-value">
              {formatDate({ date: calculatedResult?.endDate ?? "" })}
            </div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Redemption date:</div>
            <div className="lock__details-value">Aug 6 , 2022 05:00 AM</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedSection;
