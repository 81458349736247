import TetherIcon from "assets/images/tether.png";
import "./style.scss";

interface PropTypes {
  project?: string;
  detail?: string;
}

export default function Project({ project, detail }: PropTypes) {
  return (
    <div className="project-container">
      <div className="project-image">
        <img src={TetherIcon} alt={project} />
      </div>
      <div>
        <div className="Project-name">{project}</div>
        <div className="detail">{detail}</div>
      </div>
    </div>
  );
}
