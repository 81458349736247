import { useAuthorization } from "models";
import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "utils";

enum FILTER_BY {
  "WEEK" = "week",
  "MONTH" = "month",
  "YEAR" = "year",
  "DAY" = "day",
}

const FILTER_OPTIONS: Array<{ label: string; value: FILTER_BY }> = [
  { label: "Week", value: FILTER_BY.WEEK },
  { label: "Month", value: FILTER_BY.MONTH },
  { label: "Year", value: FILTER_BY.YEAR },
  { label: "24h", value: FILTER_BY.DAY },
];

const useFilterState = () => useState<FILTER_BY>(FILTER_BY.WEEK);

const useAvailableBalance = ({ filterBy }: { filterBy: FILTER_BY }) => {
  const { token } = useAuthorization();
  const key = "/users/balance"; // TODO: update the endpoint

  return useSWR<Response>(token ? key : null, () =>
    fetcher.get(key, { filterBy }),
  );
};

export {
  FILTER_OPTIONS as AVAILABLE_BALANCE_FILTER_BY,
  useAvailableBalance,
  useFilterState as useAvailableBalanceFilterState,
};
