import { type PaginationState } from "@tanstack/react-table";
import { api } from "config";

type Pagination = PaginationState;

const preparePagination = ({ pageIndex, pageSize }: Pagination) => ({
  limit: pageSize,
  page: pageIndex + 1,
});

type ResponsePagination = {
  total: number;
  page: number;
  limit: number;
  pages: number;
};

const fetcher = {
  get: (url: string, params?: { [key: string]: string | number }) =>
    api.get(url, { params }).then((res) => res.data),
  post: <T, U>(url: string, { arg }: { arg: T }) =>
    api.post<U>(url, arg).then((res) => res.data),
  put: <T, U>(url: string, { arg }: { arg: T }) =>
    api.put<U>(url, arg).then((res) => res.data),
};

export { fetcher, preparePagination, type Pagination, type ResponsePagination };
