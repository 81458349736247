import { TableColumn } from "components/Shared/Table";
import Status from "components/Shared/TableCells/Status";
import WithScale from "components/Shared/TableCells/WithScale";
import Button from "components/UI/Button";
import { formatAmount, formatDate } from "utils";

const latestTransactionsTableConfig: TableColumn[] = [
  {
    header: "#",
    accessorKey: "id",
    cell: (info) => (
      <WithScale
        value={info.getValue() as any}
        type={(info.row.original as any).type}
      />
    ),
  },
  {
    header: "Amount",
    accessorKey: "amount",
    cell: (info) =>
      formatAmount({
        currency: "USD",
        amount: info.getValue() as any,
      }),
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: (info) => <Status status={info.getValue() as any} />,
  },
  {
    header: "Created At",
    accessorKey: "createdAt",
    cell: (info) => formatDate({ date: info.getValue() as any }),
  },
  {
    header: "",
    accessorKey: "walletAddress",
    cell: (info) => (
      <Button
        variant="secondary"
        title="View Information"
        arrow={true}
        onClick={() => {
          window.open(`https://etherscan.io/address/${info.getValue() as any}`);
        }}
      />
    ),
  },
];

export { latestTransactionsTableConfig };
