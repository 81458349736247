import axios from "axios";
import { useAuthorization } from "models";
import { env, getDecodedExpiryTime, logout } from "utils";

const baseURL: string = env.REACT_APP_API_URL;

/** API Instance */
const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const token = useAuthorization.getState().token;
  const tokenExpiryTime = getDecodedExpiryTime(token);

  const isTokenExpired = tokenExpiryTime
    ? Date.now() >= tokenExpiryTime * 1000
    : true;

  if (isTokenExpired) logout();
  else if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    const refreshToken = response.headers["x-refresh-token"];
    if (refreshToken) {
      useAuthorization.getState().authorize({ token: refreshToken });
    }
    return response;
  },
  (error) => {
    const statusCode = error?.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      logout();
    }

    return Promise.reject(error);
  },
);

export { api };
