import { useInvestments } from "api/hooks";
import { ReactComponent as AssetIcon } from "assets/icons/allAssets.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import PerformanceTable from "components/Perfomance/PerformanceTable";
import Button from "components/UI/Button";
import Dropdown from "components/UI/Dropdown";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import { useRedirect } from "routes/hooks";
import "./style.scss";

const Performance = (): JSX.Element => {
  const { goToPath } = useRedirect();
  const { data: investmentsInfo } = useInvestments({
    pageIndex: 1,
    pageSize: 10,
  });

  return (
    <div className="performance">
      <div className="performance__header">
        <div className="performance__header-left">
          <Subtitle subtitle="All Assets" icon={<AssetIcon />} />
          <Title mainTitle="Performance" badge="[001]" />
        </div>
        <Dropdown
          options={[
            { label: "24h", value: "day" },
            { label: "Year", value: "year" },
            { label: "All", value: "all" },
          ]}
          value="day"
          onChange={() => {
            //
          }}
        />
      </div>
      <div className="performance__body">
        <PerformanceTable />
      </div>
      <div className="performance__footer">
        <div className="performance__profit">
          Total Profit: <span>{investmentsInfo?.summary.totalProfit}</span>
        </div>
        <div className="performance__actions">
          <Button
            title="Withdraw"
            icon={<PlusIcon />}
            variant="secondary"
            onClick={() => {
              goToPath("/withdraw");
            }}
          />
          <Button
            title="Compound"
            icon={<PlusIcon />}
            variant="primary"
            onClick={() => {
              goToPath("/buy");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Performance;
