import FallbackAvatar from "assets/images/avatar.png";
import { useRedirect } from "routes/hooks";
import "./style.scss";
import { KYC_STATUS } from "config";

type Props = {
  kycStatus?: KYC_STATUS;
  name?: string;
  avatar?: string;
  hideType?: boolean;
};

const Profile = ({
  name,
  avatar,
  hideType,
  kycStatus = KYC_STATUS["PENDING"],
}: Props): JSX.Element => {
  const { goToPath } = useRedirect();

  return (
    <div
      className="profile"
      role="button"
      tabIndex={1}
      onClick={() => {
        goToPath("/profile");
      }}
    >
      <div className={`profile__photo profile__photo--${kycStatus}`}>
        <img src={avatar || FallbackAvatar} alt={name} />
      </div>
      <div className={`profile__info profile__info--${kycStatus}`}>
        <div className={`profile__info-name profile__info-name--${kycStatus}`}>
          {name}
        </div>
        {!hideType && kycStatus && (
          <div
            className={`profile__info-type profile__info-type--${kycStatus}`}
          >
            {kycStatus}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
