import { useInvestments } from "api/hooks";
import { useTablePagination } from "components/Shared/Table";
import Table from "components/Shared/Table/Table";
import { performanceTableConfig } from "./tableConfig";

const PerformanceTable = (): JSX.Element => {
  const [pagination, setPatination] = useTablePagination();
  const { data: investmentsInfo } = useInvestments(pagination);

  return (
    <Table
      data={investmentsInfo?.investments}
      rowCount={investmentsInfo?.pagination.total}
      columns={performanceTableConfig}
      onPaginationChange={setPatination}
    />
  );
};

export default PerformanceTable;
