import { ReactComponent as SubIcon } from "assets/icons/allAssets.svg";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import { PurchaseCryptoWidget } from "utils";
import "./style.scss";

const PurchaseCrypto = (): JSX.Element => {
  return (
    <div className="buy">
      <div className="buy__header">
        <div className="buy__header-left">
          <Subtitle subtitle="All Assets" icon={<SubIcon />} />
          <Title mainTitle="Buy Crypto" badge="[001]" />
        </div>
      </div>
      <div className="buy__body">
        <div className="buy__body-inner">
          <PurchaseCryptoWidget />
        </div>
      </div>
    </div>
  );
};

export default PurchaseCrypto;
