import { useRedirect } from "routes/hooks";
import "./style.scss";
import { Path } from "routes/types";

interface PropTypes {
  icon: React.ReactNode;
  title: string;
  variant: string;
  path: Path;
}
// TODO: refactor
export default function ActionButton({
  icon,
  title,
  variant,
  path,
}: PropTypes) {
  const { goToPath } = useRedirect();

  return (
    <button
      onClick={() => {
        goToPath(path);
      }}
      className={
        "action-button " +
        (variant === "red"
          ? "red"
          : variant === "green"
            ? "green"
            : variant === "white"
              ? "white"
              : variant === "orange"
                ? "orange"
                : "")
      }
    >
      <div>{variant === "white" ? title : icon}</div>
      <div>{variant === "white" ? icon : title}</div>
    </button>
  );
}
