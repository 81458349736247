import { Withdrawal } from "api/hooks";
import { ReactComponent as InfoIcon } from "assets/icons/exclamation.svg";
import Tether from "assets/images/tether.png";
import Button from "components/UI/Button";
import Modal from "components/UI/Modal";
import SelectBox from "components/UI/SelectBox";
import { useWithdraw } from "models";
import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import { fetcher, formatAmount, toast } from "utils";
import "./style.scss";

interface InvestmentCalculatorResponse {
  withdrawal: Withdrawal;
}

interface InvestmentCalculatorRequest {
  amount: number;
  walletAddress: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const WithdrawalModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const { investment, amount, setAmount, reset } = useWithdraw();

  const { trigger, isMutating } = useSWRMutation(
    "/withdrawals",
    fetcher.post<InvestmentCalculatorRequest, InvestmentCalculatorResponse>,
  );

  const onSubmit = useCallback(async () => {
    if (investment && amount)
      try {
        await trigger({ amount, walletAddress: investment?.walletAddress });
        reset();
        onClose();
      } catch (error) {
        toast.error("Something went wrong.");
      }
  }, [amount, investment, onClose, reset, trigger]);

  return (
    <Modal setModal={onClose} title="Request Withdraw" show={isOpen}>
      <div className="modal-content">
        <div className="content">
          <div className="amount">
            <div>Your Amount</div>
            <div>
              <span>You can get: </span>
              {formatAmount({
                amount: investment?.withdrawableAmount ?? 0,
                currency: "USD",
              })}
            </div>
          </div>

          <SelectBox
            options={[{ label: "Tether", value: "USDT", icon: Tether }]}
            value="USDT"
            investment={investment}
            setAmount={(amount) => setAmount({ amount })}
          />

          <div className="alert">
            <InfoIcon />
            Expert mode turns off the 'Confirm' transaction prompt, and allows
            high slippage trades that often result in bad rates and lost funds
          </div>
        </div>
        <div className="button">
          <Button
            badge="001"
            title="Request Withdraw"
            variant="primary"
            onClick={onSubmit}
            disabled={!investment || !amount}
            isLoading={isMutating}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawalModal;
