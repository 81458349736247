import { PartitionedRoutes, UnprotectedRoute } from "routes/types";
import ForgotPassword from "Views/ForgotPassword";
import ForgotPasswordTOTP from "Views/ForgotPassword/TOTP";
import Login from "Views/Login";
import Signup from "Views/Signup";

/**
 * Unprotected Routes object
 */
export const unprotectedRoutes: PartitionedRoutes<UnprotectedRoute> = {
  "/sign-in": { Element: Login, name: "Login" },
  "/sign-up": { Element: Signup, name: "Sign Up" },
  "/forgot-password": { Element: ForgotPassword, name: "Forgot Password" },
  "/forgot-password/totp": {
    Element: ForgotPasswordTOTP,
    name: "Forgot Password - TOTP",
  },
};
