import { KYC_STATUS } from "config";
import { useAuthorization } from "models";
import { useMemo } from "react";
import useSWR from "swr";
import { fetcher } from "utils";

type UserKYC = {
  status: KYC_STATUS;
  rejectionReason: string;
  lastUpdated: string;
};

const useKycStatus = () => {
  const { token } = useAuthorization();

  return useSWR<UserKYC>(token ? "/kyc/status" : null, fetcher.get);
};

const useKycMetadata = () => {
  const { data: kyc } = useKycStatus();

  const isKYCApproved = useMemo(
    () => (kyc == null ? false : [KYC_STATUS["APPROVED"]].includes(kyc.status)),
    [kyc],
  );

  return { isKYCApproved };
};

export { useKycStatus, useKycMetadata };
