import { useAuthorization } from "models";
import useSWR from "swr";
import {
  fetcher,
  Pagination,
  preparePagination,
  ResponsePagination,
} from "utils";
import { InvestmentPlan } from "./useInvestmentPlans";

enum STATUS {
  "ACTIVE" = "active",
  "INACTIVE" = "inactive",
}

type Investment = {
  id: string;
  userId: string;
  planId: string;
  plan: InvestmentPlan;
  amount: number;
  status: STATUS;
  profitEarned: number;
  withdrawableAmount: number;
  startDate: string;
  endDate: string;
  lastProfitCalculation: string;
  refundReason: string;
  createdAt: string;
  updatedAt: string;
};

type Summary = {
  totalInvested: number;
  totalProfit: number;
  activeInvestments: number;
  withdrawableAmount: number;
};

type Response = {
  investments: Array<Investment>;
  summary: Summary;
  pagination: ResponsePagination;
};

const useInvestments = ({ pageIndex, pageSize }: Pagination) => {
  const { token } = useAuthorization();
  const key = "/investments";

  return useSWR<Response>(token ? key : null, () =>
    fetcher.get(key, {
      ...preparePagination({ pageIndex, pageSize }),
    }),
  );
};

export { useInvestments };
