import { zodResolver } from "@hookform/resolvers/zod";
import { useUser } from "api/hooks";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as UserIcon } from "assets/icons/user2.svg";
import { ReactComponent as NameIcon } from "assets/icons/user3.svg";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import Select from "components/UI/Select";
import { countries, MAX_AGE, MIN_AGE } from "config";
import { useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { fetcher, logout, STRONG_PASSWORD_REGEX, toast } from "utils";
import * as z from "zod";
import "./style.scss";

interface ProfileUpdateResponse {}

interface ProfileUpdateRequest {
  fullName: string;
  username: string;
  birthday: string;
  email: string;
  country: string;
  password: string;
  confirmPassword: string;
}

const stringSchema = z.string().min(1, { message: "Required" });

const schema = z
  .object({
    username: stringSchema,
    fullName: stringSchema,
    dateOfBirth: stringSchema,
    countryOfResidence: stringSchema,
    email: stringSchema.email({ message: "Invalid email" }),
    password: stringSchema.regex(STRONG_PASSWORD_REGEX, {
      message: "Please choose an strong password",
    }),
    verifyPassword: stringSchema,
  })
  .refine((data) => data.verifyPassword === data.password, {
    message: "Please make sure your passwords match",
    path: ["verifyPassword"],
  });

type SchemaType = z.infer<typeof schema>;

const ProfileForm = () => {
  const { data: userProfile } = useUser();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: userProfile
      ? {
          email: userProfile.user.email,
          countryOfResidence: userProfile.user.country,
          dateOfBirth: userProfile.user.birthday,
          fullName: userProfile.user.fullName,
          username: userProfile.user.username,
        }
      : {},
  });

  const { trigger, isMutating } = useSWRMutation(
    "/users/profile",
    fetcher.put<ProfileUpdateRequest, ProfileUpdateResponse>,
  );

  const onSubmit = async ({
    dateOfBirth,
    countryOfResidence,
    verifyPassword,
    ...rest
  }: SchemaType) => {
    try {
      await trigger({
        country: countryOfResidence,
        birthday: dateOfBirth,
        confirmPassword: verifyPassword,
        ...rest,
      });
      toast.success("Updated successfully!");
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <form
      id="profile"
      onSubmit={handleSubmit(onSubmit)}
      className="profile-form"
    >
      <div className="profile-form__wrapper">
        <div className="profile-form__title">Account Information</div>
        <div className="profile-form__body">
          <div className="profile-form__row">
            <Input
              label="Username"
              icon={<UserIcon />}
              placeholder="Enter Your Username"
              {...register("username")}
              errorMessage={errors.username?.message}
            />
            <Input
              label="Full Name"
              icon={<NameIcon />}
              placeholder="Enter Your Full Name"
              {...register("fullName")}
              errorMessage={errors.fullName?.message}
            />
          </div>

          <div className="profile-form__row">
            <Input
              label="Date Of Birth"
              icon={<CalendarIcon />}
              min={MAX_AGE}
              max={MIN_AGE}
              type="date"
              {...register("dateOfBirth")}
              errorMessage={errors.dateOfBirth?.message}
            />
            <Input
              label="Email"
              icon={<EmailIcon />}
              placeholder="Enter Your Email"
              type="email"
              {...register("email")}
              errorMessage={errors.email?.message}
            />
          </div>
          <Select
            label="Country Of Residence"
            icon={<LocationIcon />}
            value={watch("countryOfResidence")}
            onChange={(value) =>
              setValue("countryOfResidence", value as string)
            }
            options={countries.map(({ code, name }) => ({
              value: code,
              label: name,
            }))}
            errorMessage={errors.countryOfResidence?.message}
          />
          <div className="profile-form__row">
            <Input
              label="Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              autoComplete="new-password"
              button="show"
              type="password"
              {...register("password")}
              errorMessage={errors.password?.message}
            />
            <Input
              label="Repeat Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              autoComplete="new-password"
              button="show"
              type="password"
              {...register("verifyPassword")}
              errorMessage={errors.verifyPassword?.message}
            />
          </div>
          <Button
            className="aml__button"
            type="submit"
            form="profile"
            title="Save"
            isLoading={isMutating}
            variant="secondary"
          />
        </div>
      </div>
      <div className="profile-form__logout">
        <Button variant="info" title="Logout" badge="003" onClick={logout} />
      </div>
    </form>
  );
};

export default ProfileForm;
