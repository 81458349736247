import "./style.scss";
import Header from "./Header";
import Content from "./Content";

export default function MainLayout() {
  return (
    <div className="main-layout">
      <Header />
      <Content />
    </div>
  );
}
