import Container from "components/layout/Container";
import { PurchaseCryptoProvider } from "utils";
import "./styles/index.scss";

const App = () => {
  return (
    <PurchaseCryptoProvider>
      <Container />
    </PurchaseCryptoProvider>
  );
};

export default App;
