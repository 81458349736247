import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as ReferralIcon } from "assets/icons/funnel.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as UserIcon } from "assets/icons/user2.svg";
import { ReactComponent as NameIcon } from "assets/icons/user3.svg";
import Image from "assets/images/robot.png";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import Select from "components/UI/Select";
import { countries, MAX_AGE, MIN_AGE } from "config";
import { useAuthorization } from "models";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { fetcher, STRONG_PASSWORD_REGEX, toast } from "utils";
import * as z from "zod";

interface SignUpResponse {
  id: string;
  username: string;
  email: string;
  walletAddress: string;
  token: string;
}

interface SignUpRequest {
  username: string;
  fullName: string;
  birthday: string;
  email: string;
  country: string;
  password: string;
  confirmPassword: string;
  referral: string;
}

const stringSchema = z.string().min(1, { message: "Required" });

const schema = z
  .object({
    username: z
      .string()
      .min(3, { message: "Should be at least 3 characters" })
      .max(30),
    fullName: z
      .string()
      .min(2, { message: "Should be at least 2 characters" })
      .max(100, { message: "Should not exceed 100 characters" }),
    dateOfBirth: stringSchema,
    email: stringSchema.email({ message: "Invalid email" }),
    countryOfResidence: stringSchema,
    referral: stringSchema,
    password: stringSchema.regex(STRONG_PASSWORD_REGEX, {
      message: "Please choose an strong password",
    }),
    verifyPassword: stringSchema,
  })
  .refine((data) => data.verifyPassword === data.password, {
    message: "Please make sure your passwords match",
    path: ["verifyPassword"],
  });

type SchemaType = z.infer<typeof schema>;

const SignUp = (): JSX.Element => {
  const { authorize } = useAuthorization();
  const [searchParams] = useSearchParams();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    const refCode = searchParams.get("referral");
    if (refCode) setValue("referral", refCode);
  }, [searchParams, setValue]);

  const { trigger, isMutating } = useSWRMutation(
    "/users/signup",
    fetcher.post<SignUpRequest, SignUpResponse>,
  );

  const onSubmit = async ({
    dateOfBirth,
    countryOfResidence,
    verifyPassword,
    ...rest
  }: SchemaType) => {
    try {
      const { token } = await trigger({
        country: countryOfResidence,
        birthday: dateOfBirth,
        confirmPassword: verifyPassword,
        ...rest,
      });
      authorize({ token });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className="signup-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img
              className="main-content__icon-file"
              src={Image}
              alt="Authentication"
            />
          </div>
          <div className="main-content__title">Let's Get Started!</div>
          <div className="main-content__text">
            Trusted by millions. Digital Bank Labs is a secure investing
            platform making the world of{" "}
            <span className="highlighted">web3</span> accessible to all.
          </div>
        </div>
        <form
          id="sign-up"
          onSubmit={handleSubmit(onSubmit)}
          className="main-content__form"
        >
          <div className="main-content__form-row">
            <Input
              label="Username"
              icon={<UserIcon />}
              placeholder="Enter Your Username"
              {...register("username")}
              errorMessage={errors.username?.message}
            />
            <Input
              label="Full Name"
              icon={<NameIcon />}
              placeholder="Enter Your Full Name"
              {...register("fullName")}
              errorMessage={errors.fullName?.message}
            />
          </div>
          <Input
            label="Date Of Birth"
            icon={<CalendarIcon />}
            min={MAX_AGE}
            max={MIN_AGE}
            type="date"
            {...register("dateOfBirth")}
            errorMessage={errors.dateOfBirth?.message}
          />
          <Input
            label="Email"
            icon={<EmailIcon />}
            placeholder="Enter Your Email"
            type="email"
            {...register("email")}
            errorMessage={errors.email?.message}
          />
          <Select
            label="Country Of Residence"
            icon={<LocationIcon />}
            value={watch("countryOfResidence")}
            onChange={(value) =>
              setValue("countryOfResidence", value as string)
            }
            options={countries.map(({ code, name }) => ({
              value: code,
              label: name,
            }))}
            errorMessage={errors.countryOfResidence?.message}
          />
          <div className="main-content__form-row">
            <Input
              label="Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              autoComplete="new-password"
              button="show"
              type="password"
              {...register("password")}
              errorMessage={errors.password?.message}
            />
            <Input
              label="Repeat Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              autoComplete="new-password"
              button="show"
              type="password"
              {...register("verifyPassword")}
              errorMessage={errors.verifyPassword?.message}
            />
          </div>
          <div className="main-content__form-row">
            <Input
              label="Referral Code"
              icon={<ReferralIcon />}
              placeholder="Enter Your referral code"
              type="text"
              {...register("referral")}
              errorMessage={errors.referral?.message}
            />
          </div>
        </form>
      </div>
      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you Already have an account
            <Link className="highlighted" to="/sign-in">
              {" "}
              Login Now
            </Link>
          </span>
        </div>
        <div className="main-content__button">
          <Button
            type="submit"
            form="sign-up"
            title="Signup"
            isLoading={isMutating}
            variant="primary"
            badge="001"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
