import { useUser } from "api/hooks";
import Button from "components/UI/Button";
import { AML_STATUS } from "config";
import { useMemo } from "react";
import "./style.scss";

const AML_DETAILS: Record<
  AML_STATUS,
  {
    label: string;
    action: { label: string };
  }
> = {
  [AML_STATUS.APPROVED]: {
    label: "AML Confirmed",
    action: { label: "AML Information" },
  },
  [AML_STATUS.REJECTED]: {
    label: "AML Not Confirmed",
    action: { label: "Start AML" },
  },
  [AML_STATUS.PENDING]: {
    label: "AML Confirming",
    action: { label: "AML Information" },
  },
  [AML_STATUS.REVIEWING]: {
    label: "AML Confirming",
    action: { label: "AML Information" },
  },
};

const AML = (): JSX.Element => {
  const { data: userProfile } = useUser();

  const amlStatus = useMemo(
    () =>
      userProfile == null ? AML_STATUS["PENDING"] : userProfile.user.amlStatus,
    [userProfile],
  );

  return (
    <div className="aml">
      <div className={`aml__state aml__state--${amlStatus}`}>
        {AML_DETAILS[amlStatus].label}
      </div>
      <Button
        className="aml__button"
        variant="secondary"
        title={AML_DETAILS[amlStatus].action.label}
        onClick={() => {
          // Initiate AML SDK
        }}
      />
    </div>
  );
};

export default AML;
