import PrimaryText from "components/Shared/TableCells/PrimaryText";
import WithArrow from "components/Shared/TableCells/WithArrow";

import "./style.scss";
import { useMemo } from "react";
import ListableTable from "components/UI/ListableTable";

interface PropTypes {
  data: any[];
}

export default function TradeTable({ data }: PropTypes) {
  const columns = [
    "Project",
    "Trader",
    "Closed P&L",
    "QTY",
    "Entry Price",
    "Exit Price",
  ];

  const transformedData = useMemo(() => {
    return data.map((item) => [
      <div className="trade-project">
        <div className="trade-project__images">
          <img src={item.project.image1} alt="" />
          <img src={item.project.image2} alt="" />
        </div>
        <div className="trade-project__info">
          <div className="trade-project__token">
            {item.project.token}
            <span className="trade-project__status">{item.project.status}</span>
          </div>
          <div className="trade-project__date">{item.project.date}</div>
        </div>
      </div>,
      <PrimaryText text={item.trader} />,
      <PrimaryText text={item.closedPL} />,
      <WithArrow type="increase" value={item.QTY} />,
      <PrimaryText text={item.entryPrice} />,
      <PrimaryText text={item.exitPrice} />,
    ]);
  }, [data]);

  const extraData = useMemo(() => {
    return data.map((item) => [
      {
        id: "openingFee",
        label: "Opening Fee",
        data: item.openingFee,
      },
      {
        id: "closingFee",
        label: "Closing Fee",
        data: item.closingFee,
      },
      {
        id: "fundingFee",
        label: "Funding Fee",
        data: item.fundingFee,
      },
      {
        id: "exitType",
        label: "Exit Type",
        data: item.exitType,
      },
    ]);
  }, [data]);

  return (
    <ListableTable
      data={transformedData}
      id="trade-table"
      templateClass="trade-row"
      extraRowClass="trade-extra-row"
      columns={columns}
      showHeader
      extraData={extraData}
    />
  );
}
