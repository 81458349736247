import formatCurrency from "currency.js";

type Fiat = "USD";
type Crypto = "USDT";
type Currency = Crypto | Fiat;

const SYMBOL: Record<Currency, string> = {
  USD: "$",
  USDT: "USDT",
};

const SUPPORTED_CRYPTO_CURRENCIES = ["USDT"];

const formatAmount = ({
  amount,
  currency,
}: {
  amount: number;
  currency: Currency;
}) => {
  const isCrypto = SUPPORTED_CRYPTO_CURRENCIES.includes(currency);

  return formatCurrency(amount, {
    symbol: SYMBOL[currency],
    pattern: isCrypto ? `# !` : `! #`,
  }).format();
};

export { formatAmount };
