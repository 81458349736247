import { ReactComponent as SubIcon } from "assets/icons/allAssets.svg";
import Subtitle from "components/UI/Subtitle";
import Tabs from "components/UI/Tabs";
import Title from "components/UI/Title";
import { useState } from "react";
import InvestorsLeaderboardTable from "./InvestorsLeaderboardTable";
import ReferralsLeaderboardTable from "./ReferralsLeaderboardTable";
import "./style.scss";

type TabOption = "traders" | "brokers";
const TAB_OPTIONS: Array<{ label: string; value: TabOption }> = [
  {
    label: "Top Traders",
    value: "traders",
  },
  {
    label: "Top Brokers",
    value: "brokers",
  },
];

const Leaderboard = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabOption>("traders");

  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <div className="leaderboard__header-left">
          <Subtitle subtitle="All Assets" icon={<SubIcon />} />
          <Title mainTitle="Leaderboard" badge="[001]" />
        </div>
        <div className="leaderboard__header-right">
          <Tabs
            value={activeTab}
            options={TAB_OPTIONS}
            onChange={(value) => setActiveTab(value as TabOption)}
          />
        </div>
      </div>
      <div className="leaderboard__body">
        {activeTab === "traders" ? (
          <InvestorsLeaderboardTable />
        ) : (
          <ReferralsLeaderboardTable />
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
