import truncateMiddle from "truncate-middle";

/**
 * @example MT84MALT011000012345MTLCAST001S ~> MT84M****T001S
 */
const truncateString = ({
  str,
  options: { extent = 4, truncation },
}: {
  str: string;
  options: {
    truncation: "start" | "end" | "middle";
    extent?: number;
  };
}): string => {
  if (str.length <= extent) return str;

  const ellipsis = "...";

  switch (truncation) {
    case "start":
      return ellipsis + str.slice(str.length - extent, str.length);
    case "end":
      return str.slice(0, extent) + ellipsis;
    case "middle":
      return truncateMiddle(str, extent, extent, ellipsis);
  }
};

export { truncateString };
