import { useKycMetadata } from "api/hooks";
import DashboardLayout from "components/layout/DashboardLayout";
import MainLayout from "components/layout/MainLayout";
import { useAuthorization } from "models";
import { useMemo } from "react";
import { protectedRoutes, unprotectedRoutes } from "routes/routingObjects";
import { Path } from "routes/types";

const useRoutingLevel = () => {
  const { token } = useAuthorization();
  const { isKYCApproved } = useKycMetadata();

  const authorizedRoute = useMemo<Path>(
    () => (isKYCApproved ? "/dashboard" : "/profile"),
    [isKYCApproved],
  );

  const { LayoutComponent, defaultRoute, routes } = useMemo(
    () => ({
      defaultRoute: token ? authorizedRoute : "/sign-in",
      routes: token ? protectedRoutes : unprotectedRoutes,
      LayoutComponent: token ? DashboardLayout : MainLayout,
    }),
    [token, authorizedRoute],
  );

  return { defaultRoute, routes, LayoutComponent };
};

export { useRoutingLevel };
