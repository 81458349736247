import { useAuthorization } from "models";
import useSWR from "swr";
import {
  fetcher,
  Pagination,
  preparePagination,
  ResponsePagination,
} from "utils";

enum STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

type Withdrawal = {
  id: string;
  userId: string;
  amount: number;
  status: STATUS;
  walletAddress: string;
  processedBy: string;
  processedAt: string;
  rejectionReason: string;
  createdAt: string;
  updatedAt: string;
};

type Response = {
  withdrawals: Array<Withdrawal>;
  pagination: ResponsePagination;
};

const useWithdrawals = ({ pageIndex, pageSize }: Pagination) => {
  const { token } = useAuthorization();
  const key = "/withdrawals";

  return useSWR<Response>(token ? key : null, () =>
    fetcher.get(key, {
      ...preparePagination({ pageIndex, pageSize }),
    }),
  );
};

export { useWithdrawals, type Withdrawal };
