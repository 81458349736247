import "./style.scss";

interface PropTypes {
  mainTitle?: string;
  badge?: string;
}

export default function Title({ mainTitle, badge }: PropTypes) {
  return (
    <div className="title">
      <div className="title__text">{mainTitle}</div>
      <div className="title__badge">{badge}</div>
    </div>
  );
}
