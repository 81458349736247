import { ReactComponent as AssetIcon } from "assets/icons/allAssets.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Image1 from "assets/images/image1.png";
import Image2 from "assets/images/image2.png";
import Image3 from "assets/images/image3.png";
import Image4 from "assets/images/image4.png";
import Image5 from "assets/images/image5.png";
import LearnCard from "components/Learn/LearnCard";
import Button from "components/UI/Button";
import Subtitle from "components/UI/Subtitle";
import Title from "components/UI/Title";
import "./style.scss";

const mockData = [
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image1,
  },
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image2,
  },
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image1,
  },
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image3,
  },
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image4,
  },
  {
    title: "BITCOIN HOLDERS! I FOUND WHO IS ACTUAL",
    image: Image5,
  },
];

const Learn = (): JSX.Element => {
  return (
    <div className="learn">
      <div className="learn__header">
        <div className="learn__header-left">
          <Subtitle subtitle="All Assets" icon={<AssetIcon />} />
          <Title mainTitle="Learn Crypto" badge="[003]" />
          <p className="learn__subtitle">
            One of our missions is to transmit the best information to our
            customers. We have prepared a selection of specific content so that
            they can learn through our platform.
          </p>
        </div>
        <div className="learn__header-right">
          <Button
            variant="primary"
            title="Show More"
            icon={<PlusIcon />}
            onClick={() => {
              window.open("https://www.youtube.com/");
            }}
          />
        </div>
      </div>
      <div className="learn__body">
        {mockData.map((item, index) => (
          <LearnCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default Learn;
