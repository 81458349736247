import { PartitionedRoutes, ProtectedRoute } from "routes/types";
import Buy from "Views/BuyCrypto";
import Dashboard from "Views/Dashboard";
import LeaderBoard from "Views/Leaderboard";
import Learn from "Views/Learn";
import Performance from "Views/Performance";
import Profile from "Views/ProfileSetting";
import Stake from "Views/Stake";
import TradeDashboard from "Views/TradeDashboard";
import WalletManagement from "Views/WalletManagment";
import Withdraw from "Views/Withdrawal";

/**
 * Protected Routes object
 */
export const protectedRoutes: PartitionedRoutes<ProtectedRoute> = {
  "/dashboard": { Element: Dashboard, name: "Dashboard" },
  "/buy": { Element: Buy, name: "Buy Crypto" },
  "/stake": { Element: Stake, name: "Stake" },
  "/withdraw": { Element: Withdraw, name: "Withdrawal" },
  "/performance": { Element: Performance, name: "Performance" },
  "/leader-board": { Element: LeaderBoard, name: "Leader Board" },
  "/wallet-management": {
    Element: WalletManagement,
    name: "Wallet Management",
  },
  "/profile": { Element: Profile, name: "Profile" },
  "/trade-dashboard": { Element: TradeDashboard, name: "Trade Dashboard" },
  "/learn": { Element: Learn, name: "Learn" },
};
