import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRoutingLevel } from "routes/hooks";
import { RouteObject } from "routes/types";
import "./style.scss";

const Container = () => {
  const { routes, defaultRoute, LayoutComponent } = useRoutingLevel();

  return (
    <div className="container">
      <Routes>
        <Route element={<LayoutComponent />}>
          {Object.keys(routes).map((path) => {
            const { Element } = routes[path as never] as RouteObject;
            return <Route key={path} path={path} element={<Element />} />;
          })}
          <Route
            path="*"
            element={<Navigate to={defaultRoute} replace={true} />}
          />
        </Route>
      </Routes>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "2px",
            background: "#0b0c0d",
            color: "#ffffff",
            fontSize: "0.85rem",
            fontFamily: "'Chakra Petch', sans-serif",
          },
        }}
      />
    </div>
  );
};

export default Container;
