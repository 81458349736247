import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher, Pagination, preparePagination } from "utils";

enum PERIOD {
  "ALL_TIME" = "all_time",
  "MONTHLY" = "monthly",
  "WEEKLY" = "weekly",
}

type InvestorsLeaderboardEntry = {
  rank: number;
  userId: string;
  username: string;
  totalInvested: number;
  totalProfit: number;
  activeInvestments: number;
};

type Response = {
  period: PERIOD;
  leaderboard: Array<InvestorsLeaderboardEntry>;
  userRank: InvestorsLeaderboardEntry;
};

const useInvestorsLeaderboard = ({ pageIndex, pageSize }: Pagination) => {
  const { token } = useAuthorization();
  const key = "/leaderboard/investors";

  return useSWR<Response>(token ? key : null, () =>
    fetcher.get(key, {
      ...preparePagination({ pageIndex, pageSize }),
      period: PERIOD.ALL_TIME,
    }),
  );
};

export { useInvestorsLeaderboard };
