import { KYC_STATUS } from "config";
import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher } from "utils";

type UserProfile = {
  user: {
    id: string;
    username: string;
    fullName: string;
    email: string;
    country: string;
    birthday: string;
    phoneNumber: string;
    address: string;
    profileImage: string;
    walletAddress: string;
    kycStatus: KYC_STATUS;
    amlStatus: KYC_STATUS;
    referral: string;
  };
};

const useUser = () => {
  const { token } = useAuthorization();

  return useSWR<UserProfile>(token ? "/users/profile" : null, fetcher.get);
};

export { useUser, type UserProfile };
