import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { ReactComponent as ArrowLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/chevron-right.svg";
import Dropdown from "components/UI/Dropdown";
import "./styles.scss";

type Column = ColumnDef<Record<string, unknown>>;
type Props = {
  columns: Column[];
  data?: Record<string, unknown>[];
  isLoading?: boolean;
  withPagination?: boolean;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  rowCount?: number;
};

const Table = ({
  columns,
  data = [],
  isLoading = false,
  withPagination = true,
  pagination = { pageIndex: 0, pageSize: 10 },
  onPaginationChange,
  rowCount = 10,
}: Props): JSX.Element => {
  const table = useReactTable({
    data,
    columns,
    rowCount,
    state: { pagination },
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <div className="table-wrapper">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {withPagination && (
        <div className="pagination-wrapper">
          <div className="size-selector">
            <p>Rows per page:</p>
            <Dropdown
              value={table.getState().pagination.pageSize}
              options={[
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 40, label: "40" },
                { value: 50, label: "40" },
                { value: 100, label: "100" },
              ]}
              onChange={(value) => {
                table.setPageSize(Number(value));
              }}
            />
          </div>
          <div className="actions">
            <span>
              {`${table.getState().pagination.pageIndex + 1}-${table.getState().pagination.pageSize} of ${table.getRowCount()}`}
            </span>
            <button
              className="left"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ArrowLeft />
            </button>
            <button
              className="right"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="loading-wrapper">
          <span>Loading...</span>
        </div>
      ) : null}
    </div>
  );
};

export default Table;
export { type Column as TableColumn };
