import "./style.scss";

type Option = {
  label: string;
  value: string;
};
type Value = Option["value"];

type Props = {
  options: Array<Option>;
  value: Value;
  onChange: (value: Value) => void;
};

const Tabs = ({ options, value, onChange }: Props): JSX.Element => {
  return (
    <div className="tabs">
      {options.map((option, index) => (
        <div
          key={option.value}
          className={`tabs__item ${option.value === value ? "tabs__item--active" : ""}`}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
