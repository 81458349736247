import "./style.scss";
import Navbar from "../Navbar";

interface HeaderProps {
  onToggle: () => void;
  isOpen: boolean;
}

export default function Header({ onToggle, isOpen }: HeaderProps) {
  return (
    <div className="dashboard__header">
      <Navbar onToggle={onToggle} isOpen={isOpen} />
    </div>
  );
}
