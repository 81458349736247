import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as UserIcon } from "assets/icons/user2.svg";
import Image from "assets/images/robot.png";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import { useAuthorization } from "models";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { fetcher, toast } from "utils";
import * as z from "zod";

interface LoginResponse {
  token: string;
}

interface LoginRequest {
  identifier: string;
  password: string;
}

const schema = z.object({
  identifier: z.string().min(1, { message: "Required" }),
  password: z.string().min(1, { message: "Required" }),
});

type SchemaType = z.infer<typeof schema>;

const Login = (): JSX.Element => {
  const { authorize } = useAuthorization();

  const { trigger, isMutating } = useSWRMutation(
    "/users/login",
    fetcher.post<LoginRequest, LoginResponse>,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async ({ identifier, password }: SchemaType) => {
    try {
      const { token } = await trigger({ identifier, password });
      authorize({ token });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className="login-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img
              className="main-content__icon-file"
              src={Image}
              alt="Authentication"
            />
          </div>
          <div className="main-content__title">Welcome Back!</div>
          <div className="main-content__text">
            Trusted by millions. Digital Bank Labs is a secure investing
            platform making the world of{" "}
            <span className="highlighted">web3</span> accessible to all.
          </div>
        </div>
        <form
          id="login"
          className="main-content__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            label="Username"
            icon={<UserIcon />}
            placeholder="Enter Your Username"
            errorMessage={errors.identifier?.message}
            {...register("identifier")}
          />
          <Input
            label="Password"
            icon={<PasswordIcon />}
            placeholder="Enter Your Password"
            autoComplete="current-password"
            button="show"
            type="password"
            errorMessage={errors.password?.message}
            {...register("password")}
          />

          <Link to="/forgot-password" className="main-content__link">
            Forgot your Password?
          </Link>
        </form>
      </div>
      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you dont have an account
            <Link className="highlighted" to="/sign-up">
              {" "}
              Sign up now
            </Link>
          </span>
        </div>
        <div className="main-content__button">
          <Button
            form="login"
            type="submit"
            title="Login"
            badge="001"
            variant="primary"
            isLoading={isMutating}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
