import logo from "assets/images/logo.svg";
import "./style.scss";

export default function Logo() {
  return (
    <div className="logo">
      <div className="logo__img">
        <img src={`${logo}`} alt="" />
      </div>
    </div>
  );
}
