import { DateTime } from "luxon";

const formatDate = ({
  date,
  format = "ff",
}: {
  date: string;
  format?: string;
}) => DateTime.fromISO(date).toFormat(format);

export { formatDate };
