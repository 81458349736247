import { zodResolver } from "@hookform/resolvers/zod";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import Image from "assets/images/robot.png";
import Button from "components/UI/Button";
import Input from "components/UI/Input";
import OTP from "components/UI/OTP";
import { useAuthorization } from "models";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useRedirect } from "routes/hooks";
import useSWRMutation from "swr/mutation";
import { fetcher, STRONG_PASSWORD_REGEX, toast } from "utils";
import * as z from "zod";

interface ForgotPasswordTOTPResponse {
  token: string;
}

interface ForgotPasswordTOTPRequest {
  email: string;
  totp: string;
  newPassword: string;
  confirmPassword: string; // TODO: Could be removed, since we are verifying passwords match on the front-end
}

const stringSchema = z.string().min(1, { message: "Required" });

const schema = z
  .object({
    totp: stringSchema.max(6),
    password: stringSchema.regex(STRONG_PASSWORD_REGEX, {
      message: "Please choose an strong password",
    }),
    verifyPassword: stringSchema,
  })
  .refine((data) => data.verifyPassword === data.password, {
    message: "Please make sure your passwords match",
    path: ["verifyPassword"],
  });

type SchemaType = z.infer<typeof schema>;

const ForgotPasswordTOTP = (): JSX.Element => {
  const { goToPath } = useRedirect();
  const { authorize } = useAuthorization();
  const [searchParams] = useSearchParams();

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    const email = searchParams.get("email");
    if (!email) {
      goToPath("/forgot-password");
    }
  }, [goToPath, searchParams]);

  const { trigger, isMutating } = useSWRMutation(
    "/users/reset-password",
    fetcher.post<ForgotPasswordTOTPRequest, ForgotPasswordTOTPResponse>,
  );

  const onSubmit = async ({
    totp,
    password: newPassword,
    verifyPassword: confirmPassword,
  }: SchemaType) => {
    const email = searchParams.get("email");

    if (email)
      try {
        const { token } = await trigger({
          email,
          totp,
          newPassword,
          confirmPassword,
        });
        authorize({ token });
      } catch (error) {
        toast.error("Something went wrong.");
      }
  };

  return (
    <div className="forgot-password-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img src={Image} className="main-content__icon-file" alt="" />
          </div>
          <div className="main-content__title">Forget Password</div>
          <div className="main-content__text">
            Trusted by millions. Creso is a secure wallet making the world of{" "}
            <span className="highlighted">web3</span> accessible to all.
          </div>
        </div>
        <form
          id="forgot-password-totp"
          onSubmit={handleSubmit(onSubmit)}
          className="main-content__form"
        >
          <OTP
            value={watch("totp")}
            label="Verification Code"
            length={6}
            id="verification"
            onChange={(value) => setValue("totp", value)}
          />
          <div className="main-content__form-row">
            <Input
              label="Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              button="show"
              autoComplete="new-password"
              type="password"
              {...register("password")}
              errorMessage={errors.password?.message}
            />
            <Input
              label="Repeat Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              autoComplete="new-password"
              button="show"
              type="password"
              {...register("verifyPassword")}
              errorMessage={errors.verifyPassword?.message}
            />
          </div>
        </form>
      </div>

      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you need help
            <Link className="highlighted" to="/sign-up">
              {" "}
              Contact Us
            </Link>
          </span>
        </div>
        <div className="main-content__button">
          <Button
            type="submit"
            form="forgot-password-totp"
            isLoading={isMutating}
            title="Login"
            variant="primary"
            badge="001"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordTOTP;
