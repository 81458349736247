import { useKycMetadata } from "api/hooks";
import { ReactComponent as WithdralsIcon } from "assets/icons/analitycs.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as DblIcon } from "assets/icons/dblcard.svg";
import { ReactComponent as BuyIcon } from "assets/icons/dollar.svg";
import { ReactComponent as LearnIcon } from "assets/icons/learn.svg";
import { ReactComponent as LockIcon } from "assets/icons/Lock.svg";
import { ReactComponent as PerformanceIcon } from "assets/icons/Menu.svg";
import { ReactComponent as LeaderboardIcon } from "assets/icons/star-favorite-circle.svg";
import { ReactComponent as TradeIcon } from "assets/icons/trade.svg";
import { ReactComponent as StakeIcon } from "assets/icons/user.svg";
import Logo from "components/Shared/Logo";
import { NavLink } from "react-router-dom";
import Footer from "../Footer";
import "./style.scss";

interface ItemProps {
  icon: React.ReactNode;
  title: string;
  badge: string;
  link: string;
  isLocked?: boolean;
}

const items: ItemProps[] = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    badge: "001",
    link: "/dashboard",
  },
  {
    icon: <StakeIcon />,
    title: "Stake & Invest",
    badge: "",
    link: "/stake",
  },
  {
    icon: <WithdralsIcon />,
    title: "Withdrawals",
    badge: "",
    link: "/withdraw",
  },
  {
    icon: <PerformanceIcon />,
    title: "Performance",
    badge: "",
    link: "/performance",
  },
  {
    icon: <LeaderboardIcon />,
    title: "Leaderboard",
    badge: "",
    link: "/leader-board",
  },
  {
    icon: <BuyIcon />,
    title: "Buy Crypto",
    badge: "",
    link: "/buy",
    isLocked: true,
  },
  {
    icon: <DblIcon />,
    title: "DBL Crypto Card",
    badge: "",
    link: "/wallet-management",
  },
  {
    icon: <LearnIcon />,
    title: "Learn",
    badge: "",
    link: "/learn",
  },
  {
    icon: <TradeIcon />,
    title: "Trade Dashboard",
    badge: "",
    link: "/trade-dashboard",
  },
];

export default function Sidebar({
  onToggle,
  isOpen,
}: {
  onToggle: () => void;
  isOpen: boolean;
}) {
  const { isKYCApproved } = useKycMetadata();

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <Logo />
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__title"> / Main Menu</div>
        <div className="sidebar__menu-items">
          {items.map((item) => (
            <NavLink
              {...(isKYCApproved
                ? {
                    onClick: () => {
                      isOpen && onToggle();
                    },
                    to: item.link,
                  }
                : { to: "/" })}
              className={({ isActive }) =>
                `sidebar__menu-item ${isActive && "active"} ${!isKYCApproved && "disabled"}`
              }
              key={`sidebar-item-${item.link}`}
            >
              <div className="sidebar__menu-item--icon">{item.icon}</div>
              <div className="sidebar__menu-item--title">{item.title}</div>
              <div className="sidebar__menu-item--badge">{item.badge}</div>
              {item.isLocked && (
                <button className="sidebar__menu-item--lock">
                  <LockIcon />
                </button>
              )}
            </NavLink>
          ))}
        </div>
        <hr className="sidebar__divider" />
        <Footer />
      </div>
    </div>
  );
}
