import { Link } from "react-router-dom";
import "./style.scss";

interface PropTypes {
  data: { title: string; image: string };
}
export default function LearnCard({ data }: PropTypes) {
  return (
    <Link to="" className="learn-card">
      <div className="title">{data.title}</div>
      <div className="image">
        <img src={data.image} alt="" />
      </div>
    </Link>
  );
}
