import "./style.scss";
import { ReactComponent as Arrow } from "assets/icons/arrowdown.svg";

interface PropTypes {
  type?: string;
  value?: string;
}
export default function WithArrow({ type, value }: PropTypes) {
  return (
    <div
      className={"percent " + (type === "increase" ? "increase" : "decrease")}
    >
      <div style={type === "increase" ? { rotate: "180deg" } : {}}>
        <Arrow />
      </div>
      <div>{value}</div>
    </div>
  );
}
