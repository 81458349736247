import "./style.scss";

interface PropTypes {
  subtitle?: string;
  icon?: React.ReactNode;
}
export default function Subtitle({ subtitle, icon }: PropTypes) {
  return (
    <div className="subtitle">
      <div className="subtitle__icon">{icon}</div>
      <div className="subtitle__text">{subtitle}</div>
    </div>
  );
}
