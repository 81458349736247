import { useAuthorization } from "models";
import useSWR from "swr";
import { fetcher } from "utils";

enum STATUS {
  "ACTIVE" = "active",
  "INACTIVE" = "inactive",
}

type InvestmentPlan = {
  id: string;
  name: string;
  description: string;
  minAmount: number;
  maxAmount: number;
  profitRate: number;
  duration: number;
  features: Array<string>;
  status: STATUS;
};

type Response = {
  plans: Array<InvestmentPlan>;
};

const useInvestmentPlans = () => {
  const { token } = useAuthorization();

  return useSWR<Response>(token ? "/investments/plans" : null, fetcher.get);
};

export {
  useInvestmentPlans,
  type InvestmentPlan,
  STATUS as INVESTMENT_PLAN_STATUS,
};
